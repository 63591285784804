<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            :items="getProjectList"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="formAdd">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان پروژه"
                        outlined
                        dense
                        hide-details
                        v-model="addData.projectTitle"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-row>
                        <v-col cols="2"
                          ><v-icon
                            type="button"
                            class="mt-3"
                            @click="show1 = true"
                          >
                            mdi-calendar
                          </v-icon></v-col
                        >
                        <v-col cols="10">
                          <v-text-field
                            v-model="addData.startDate"
                            id="addData.startDate"
                            type="text"
                            outlined
                            dense
                            label="تاریخ شروع"
                            @click="show1 = true"
                            :rules="requiredRule"
                            readonly
                          ></v-text-field>
                          <date-picker
                            v-model="addData.startDate"
                            :show="show1"
                            :editable="true"
                            :auto-submit="true"
                            custom-input="#addData.startDate"
                            @close="show1 = false"
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" lg="6">
                      <v-row>
                        <v-col cols="2"
                          ><v-icon
                            type="button"
                            class="mt-3"
                            @click="show2 = true"
                          >
                            mdi-calendar
                          </v-icon></v-col
                        >
                        <v-col cols="10">
                          <v-text-field
                            v-model="addData.finishDate"
                            id="addData.finishDate"
                            type="text"
                            outlined
                            dense
                            label="تاریخ پایان"
                            @click="show2 = true"
                            :rules="requiredRule"
                            readonly
                          ></v-text-field>
                          <date-picker
                            v-model="addData.finishDate"
                            :show="show2"
                            :editable="true"
                            :auto-submit="true"
                            custom-input="#addData.finishDate"
                            @close="show2 = false"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        label="گروه پروژه"
                        hide-details
                        outlined
                        :items="getProjectgroup.records"
                        item-text="projectGroupTitle"
                        item-value="projectGroupId"
                        dense
                        v-model="addData.projectGroupFk"
                        :rules="requiredRule"
                      ></v-select>
                    </v-col>
                    <!-- <v-col cols="12">
                      <v-combobox
                        v-model="addData.Tags"
                        label="برچسب ها"
                        :search-input.sync="search"
                        hide-selected
                        multiple
                        outlined
                        dense
                        persistent-hint
                        small-chips
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                No results matching "<strong>{{
                                  search
                                }}</strong
                                >". Press <kbd>enter</kbd> to create a new one
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-col> -->
                    <!-- <v-col cols="12">
                      <v-file-input
                        v-model="image"
                        color="deep-purple-accent-4"
                        counter
                        label="انتخاب فایل"
                        placeholder="انتخاب فایل"
                        prepend-icon="mdi-paperclip"
                        variant="outlined"
                        :show-size="1000"
                        v-show="true"
                        ref="fileInput"
                        accept="image/*"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" v-if="addData.cover">
                      <v-img
                        class="mx-auto"
                        min-width="100"
                        min-height="100"
                        ref="image"
                        max-width="200"
                        max-height="200"
                        :src="`https://crm.businesssoftware.ir/${addData.cover}`"
                      ></v-img>
                    </v-col> -->
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان پروژه"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.projectTitle"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-row>
                        <v-col cols="2"
                          ><v-icon
                            type="button"
                            class="mt-3"
                            @click="show3 = true"
                          >
                            mdi-calendar
                          </v-icon></v-col
                        >
                        <v-col cols="10">
                          <v-text-field
                            v-model="editData.data.startDate"
                            id="editData.data.startDate"
                            type="text"
                            outlined
                            dense
                            label="تاریخ شروع"
                            @click="show3 = true"
                            :rules="requiredRule"
                          ></v-text-field>
                          <date-picker
                            v-model="editData.data.startDate"
                            :show="show3"
                            :auto-submit="true"
                            :editable="true"
                            custom-input="#editData.data.startDate"
                            @close="show3 = false"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-row>
                        <v-col cols="2"
                          ><v-icon
                            type="button"
                            class="mt-3"
                            @click="show4 = true"
                          >
                            mdi-calendar
                          </v-icon></v-col
                        >
                        <v-col cols="10">
                          <v-text-field
                            v-model="editData.data.finishDate"
                            id="editData.data.finishDate"
                            type="text"
                            outlined
                            dense
                            label="تاریخ پایان"
                            @click="show4 = true"
                            :rules="requiredRule"
                            readonly
                          ></v-text-field>
                          <date-picker
                            v-model="editData.data.finishDate"
                            :show="show4"
                            :auto-submit="true"
                            :editable="true"
                            custom-input="#editData.data.finishDate"
                            @close="show4 = false"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        label="گروه پروژه"
                        hide-details
                        outlined
                        :items="getProjectgroup.records"
                        item-text="projectGroupTitle"
                        item-value="projectGroupId"
                        dense
                        v-model="editData.data.projectGroupFk"
                        :rules="requiredRule"
                      ></v-select>
                    </v-col>
                    <!-- <v-col cols="12">
                      <v-combobox
                        v-model="editData.data.projectTags"
                        :items="editData.data.tags"
                        label="برچسب ها"
                        :search-input.sync="search"
                        multiple
                        outlined
                        dense
                        persistent-hint
                        small-chips
                        :hide-no-data="false"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                No results matching "<strong>{{
                                  search
                                }}</strong
                                >". Press <kbd>enter</kbd> to create a new one
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-col> -->
                    <!-- <v-col cols="12">
                      <v-file-input
                        v-model="image"
                        color="deep-purple-accent-4"
                        counter
                        label="انتخاب فایل"
                        placeholder="انتخاب فایل"
                        prepend-icon="mdi-paperclip"
                        variant="outlined"
                        :show-size="1000"
                        v-show="true"
                        ref="fileInput"
                        accept="image/*"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" v-if="editData.data.cover">
                      <v-img
                        class="mx-auto"
                        min-width="100"
                        min-height="100"
                        ref="image"
                        max-width="200"
                        max-height="200"
                        :src="`https://crmapi.dayavo.com/${editData.data.cover}`"
                      ></v-img>
                    </v-col> -->
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import AccountingSystem from "../../components/AccountingSystem.vue";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { myMixin } from "../../../mixins.js";
import moment from "jalali-moment";
import { mapGetters } from "vuex";
export default {
  name: "Banks",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      searchTest: "",
      select: [],
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      image: null,
      search: null,
      activator: null,
      attach: null,
      addData: {
        url: "project/create/",
        formName: "formAdd",
        projectTitle: null,
        cover: null,
        finishDate: null,
        startDate: null,
        projectGroupFk: null,
        tags: [],
        test: this.$refs,
      },
      editData: {
        url: "project/update/",
        formName: "formEdit",
        data: {
          projectTitle: null,
          cover: null,
          finishDate: null,
          startDate: null,
          projectGroupFk: null,
          projectTags: [],
        },
      },
      removeData: { url: "project/delete/" },
      headers: [
        {
          text: "عنوان",
          align: "start",
          value: "projectTitle",
        },
        {
          text: "تاریخ شروع",
          align: "start",
          value: "startDate",
        },
        {
          text: "تاریخ اتمام",
          align: "start",
          value: "finishDate",
        },
        {
          text: "دسته",
          align: "start",
          value: "projectGroupFk",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["getProjectList", "getProjectgroup", "getUrl"]),
  },
  created() {
    this.getProject();
    this.getProjectGroup();
  },
  methods: {
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "project/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "project/list/",
        Page: item,
        phrase: this.searchTest,
      });
    },
    getProject() {
      this.$store.dispatch("sendList", {
        url: "project/list/",
        page: 1,
        Phrase: "",
      });
    },
    getProjectGroup() {
      this.$store.dispatch("sendList", {
        url: "projectgroup/list/",
        page: 1,
        Phrase: "",
      });
    },
    getData(item) {
      axios
        .get(`${this.getUrl}/project/get/`, {
          params: {
            Id: item.projectId,
            Language: 0,
          },
        })
        .then((response) => {
          this.select = [];
          this.editData.data = response.data.record;
          this.editData.data.startDate = this.toPersianDate(
            response.data.record.startDate
          );
          this.editData.data.finishDate = this.toPersianDate(
            response.data.record.finishDate
          );
          this.select = response.data.tags;
          this.editData.data.projectTags = [];
          this.editData.data.test = this.$refs;
          for (const item of this.select) {
            this.editData.data.projectTags.push(item.phrase);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    remove() {
      this.$refs.image.src = "";
    },
    updateStartDate(date) {
      this.addData.startDate = moment(date).locale("fa").format("YYYY/M/D");
    },
    updateFinishDate(date) {
      this.addData.finishDate = moment(date).locale("fa").format("YYYY/M/D");
    },
    updateEditFinishDate(date) {
      this.editData.data.finishDate = moment(date)
        .locale("fa")
        .format("YYYY/M/D");
    },
    updateEditStartDate(date) {
      this.editData.data.startDate = moment(date)
        .locale("fa")
        .format("YYYY/M/D");
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>

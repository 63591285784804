<template>
  <v-app>
    <v-dialog max-width="800" v-model="showUserSettingDialog">
      <UserSetting @close="showUserSettingDialog = false" />
    </v-dialog>
    <v-dialog max-width="800" v-model="showUserApproveDialog">
      <UserApprove @close="showUserApproveDialog = false" />
    </v-dialog>
    <div class="black">
      <!--  منوی بالای صفحه   -->
      <v-app-bar v-if="hasToken" color="#EEEEEE" flat app dense>
        <v-row justify="end" class="no-gutters">
          <v-col xs="1" class="d-lg-none"
            ><v-icon v-on:click="drawer = !drawer" class="mt-3">
              mdi-menu
            </v-icon>
          </v-col>

          <v-col cols="5" md="3" lg="3" class="text-end">
            <v-menu>
              <template v-slot:activator="{ attrs, on }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-badge
                    offset-y="10"
                    color="green"
                    :content="$store.state.notifications.length"
                    :value="$store.state.notifications.length"
                  >
                    <v-icon>mdi-bell-outline</v-icon>
                  </v-badge>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="data in this.$store.state.notifications"
                  :key="data.id"
                  link
                  @click="onNotificationClick(data)"
                >
                  <v-list-item-title>{{ data.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-hover v-slot="{ hover }" open-delay="200">
              <v-btn icon v-on:click="removeToken">
                <v-icon :class="{ changeColor: hover }">mdi-exit-to-app</v-icon>
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
      </v-app-bar>

      <!-- منوی سمت راست -->
      <v-navigation-drawer
        width="240"
        v-model="drawer"
        :mini-variant.sync="mini"
        app
        right
        v-if="hasToken"
        id="main-menu"
      >
        <v-card class="a fill-height" color="#08092D">
          <v-card max-width="450" class="mx-auto" tile>
            <v-toolbar color="#08092D" flat dark>
              <v-spacer></v-spacer>
              <v-app-bar-nav-icon
                center
                class="mt-6 d-none d-lg-block"
                :style="mini ? 'margin-right:-1rem' : ''"
                @click.stop="mini = !mini"
              ></v-app-bar-nav-icon>
            </v-toolbar>
          </v-card>
          <!-- پروفایل -->
          <v-card
            dark
            max-width="260"
            class="mt-md-8 mx-auto"
            style="background: rgb(120, 77, 167, 0.2)"
            :style="
              !changebackgroundColor
                ? 'background: rgb(120, 77, 167 , 0.2)'
                : `background:${getColor}`
            "
            flat
          >
            <v-list>
              <v-list-item class="px-2">
                <v-list-item-avatar>
                  <v-img :src="getUserProfile"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="caption">
                    <span style="font-familly: Vazirmatn">{{
                      this.$store.state.CurrentUser.name || ""
                    }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption">{{
                    this.$store.state.CurrentUser.username || ""
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    v-if="!closeOnClick"
                    v-on:click="closeOnClick = !closeOnClick"
                    icon
                  >
                    <v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="closeOnClick"
                    v-on:click="closeOnClick = !closeOnClick"
                    icon
                  >
                    <v-icon>mdi-menu-up</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-expand-transition>
              <v-card
                class="mx-auto"
                v-show="closeOnClick"
                max-width="260"
                :style="
                  !changebackgroundColor
                    ? 'background: rgb(120, 77, 167 , 0.1)'
                    : `background:${getColor}`
                "
                dark
                flat
              >
                <v-list dense select-strategy="classic">
                  <v-list-item-group>
                    <v-list-item
                      class="mt-1"
                      @click="showUserSettingDialog = true"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-account-key</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>تنظیمات حساب کاربری</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item @click="showUserApproveDialog = true">
                      <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>تنظیمات پروفایل</v-list-item-title>
                    </v-list-item> -->
                    <v-list-item @click="removeToken">
                      <v-list-item-icon>
                        <v-icon>mdi-exit-to-app</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>خروج</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-expand-transition>
          </v-card>

          <!-- منو های نرم افزار -->
          <v-card
            dark
            max-width="260"
            :style="
              !changebackgroundColor
                ? 'background: rgb(120, 77, 167)'
                : `background:${getColor};`
            "
            class="mx-auto mt-1 menu"
          >
            <v-list
              dense
              class="custom-link"
              style="cursor: pointer"
              shaped
              v-for="(item1, index) in getMenu"
              :key="index"
            >
              <v-list-item v-if="item1.children.length == 0">
                <v-list-item-icon>
                  <v-icon class="custom-link">mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  style="font-size: 0.75rem !important"
                  v-on:click="goToRoute(item1)"
                  >{{ item1.title }}</v-list-item-title
                >
              </v-list-item>
              <v-list-group
                dense
                v-else
                :value="items1[index].active"
                v-on:click="showM(items1[index])"
              >
                <template v-slot:appendIcon>
                  <v-icon small> mdi-chevron-down</v-icon>
                </template>
                <template v-slot:activator>
                  <v-list-item-icon
                    ><v-icon small class="custom-link">{{
                      items1[index].icon
                    }}</v-icon></v-list-item-icon
                  >
                  <v-list-item-title
                    style="font-size: 0.75rem !important"
                    v-on:click="goToRoute(item1)"
                    >{{ item1.title }}</v-list-item-title
                  >
                </template>
                <v-list
                  shaped
                  dense
                  v-for="item2 in item1.children"
                  :key="item2.id"
                >
                  <v-list-item v-if="item2.children.length == 0">
                    <v-list-item-title
                      style="font-size: 0.67rem !important"
                      v-on:click="goToRoute(item2)"
                      >{{ item2.title }}</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-group shaped v-else :value="false">
                    <template v-slot:activator>
                      <v-list-item-title
                        style="font-size: 0.67rem !important"
                        :title="item2.title"
                        v-on:click="goToRoute(item2)"
                      >
                        {{ item2.title }}
                      </v-list-item-title>
                    </template>
                    <v-list
                      dense
                      v-for="item3 in item2.children"
                      :key="item3.id"
                    >
                      <v-list-item v-if="item3.children.length == 0">
                        <v-list-item-title
                          style="font-size: 0.62rem !important"
                          v-on:click="goToRoute(item3)"
                          >{{ item3.title }}</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-group v-else :value="false">
                        <template v-slot:activator>
                          <v-list-item-title
                            style="font-size: 0.62rem !important"
                            v-on:click="goToRoute(item3)"
                            >{{ item3.title }}</v-list-item-title
                          >
                        </template>
                      </v-list-group>
                    </v-list>
                  </v-list-group>
                </v-list>
              </v-list-group>
            </v-list>
          </v-card>
        </v-card>
      </v-navigation-drawer>

      <v-main>
        <v-snackbar
          :timeout="getMessage.timeOut"
          v-model="snackbar"
          :color="getMessage.color"
          :absolute="false"
          :top="true"
          :left="true"
        >
          {{ getMessage.message }}
        </v-snackbar>
        <!-- <router-view :key="$route.fullPath"></router-view> -->
        <router-view></router-view>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import UserSetting from "./components/UserSetting.vue";
import UserApprove from "./components/UserApprove.vue";
import TaskDetail from "./latelier/client/components/tasks/TaskDetail.vue";
import TaskHistory from "./latelier/client/components/tasks/TaskHistory.vue";
import TaskExport from "./latelier/client/components/tasks/TaskExport.vue";
export default {
  components: {
    UserSetting,
    UserApprove,
    TaskDetail,
    TaskHistory,
    TaskExport,
  },
  data() {
    return {
      showUserSettingDialog: false,
      showUserApproveDialog: false,
      snackbar: false,
      activeGroup: null,
      changebackgroundColor: false,
      openTabs: [],
      menuItems: [
        {
          title: "منوی اصلی ۱",
          icon: "mdi-home",
          items: [
            {
              title: "منوی فرعی ۱-۱",
              icon: "mdi-arrow-right",
              items: [
                { title: "منوی سوم سطح ۱", icon: "mdi-arrow-right" },
                {
                  title: "منوی سوم سطح ۲",
                  icon: "mdi-arrow-right",
                  items: [
                    { title: "منوی چهارم سطح ۱", icon: "mdi-arrow-right" },
                    { title: "منوی چهارم سطح ۲", icon: "mdi-arrow-right" },
                    { title: "منوی چهارم سطح ۳", icon: "mdi-arrow-right" },
                  ],
                },
                { title: "منوی سوم سطح ۳", icon: "mdi-arrow-right" },
              ],
            },
            { title: "منوی فرعی ۱-۲", icon: "mdi-arrow-right" },
            { title: "منوی فرعی ۱-۳", icon: "mdi-arrow-right" },
          ],
        },
        {
          title: "منوی اصلی ۲",
          icon: "mdi-account",
          items: [
            { title: "منوی فرعی ۲-۱", icon: "mdi-arrow-right" },
            { title: "منوی فرعی ۲-۲", icon: "mdi-arrow-right" },
            { title: "منوی فرعی ۲-۳", icon: "mdi-arrow-right" },
          ],
        },
      ],

      drawer: true,
      mini: false,
      closeOnClick: false,
      background: "",
      root: [
        { routeName: localStorage.getItem("tenantFullName"), route: "Home" },
        {
          routeName: "کارتابل نامه های من",
          route: "MyMessage",
          icon: "mdi-language-html5",
        },
        { routeName: "نامه های داخلی", route: "DomesticLetters" },
        { routeName: "نامه های وارده", route: "IncomingLetters" },
        { routeName: "نامه های صادره", route: "LettersIssued" },
        // { routeName: "نامه های ارجاع شده", route: "Home" },
        { routeName: "کلیه نامه ها", route: "AllMessage" },
        { routeName: "پیش نویس نامه ها", route: "DraftMessage" },
        { routeName: "مدیریت امور", route: "Tasks" },
        { routeName: "لیست مشتریان", route: "CustomerList" },
        { routeName: "دسته بندی مشتریان", route: "CustomerCategory" },
        { routeName: "لیست قراردادها", route: "ContractList" },
        { routeName: "دسته بندی قراردادها", route: "ContractCategory" },
        { routeName: "لیست پروژه ها", route: "ProjectList" },
        { routeName: "دسته بندی پروژه ها", route: "ProjectCategory" },
        { routeName: "لیست اسناد", route: "AccountingList" },
        { routeName: "لیست فاکتورها", route: "AccountingCategory" },
        { routeName: "لیست محتواها", route: "ContentList" },
        { routeName: "دسته بندی محتواها", route: "ContentCategory" },
        { routeName: "لیست پیام ها", route: "TicketList" },
        { routeName: "پستهای سازمانی", route: "OrganizationalPosition" },
        { routeName: "چارت سازمانی", route: "OrganizationalUnit" },
        { routeName: "شرکتها", route: "Company" },
        { routeName: "اشخاص", route: "Person" },
        { routeName: "پلن ها", route: "Plans" },
        { routeName: "مدیریت دامین", route: "Domains" },
        { routeName: "کاربران آنلاین", route: "OnlineUser" },
        { routeName: "مدیریت کاربران", route: "MembersUser" },
        { routeName: "گروه های کاربری", route: "UserGroups" },
        { routeName: "اعضای گروه های کاربری", route: "MembersUser" },
        { routeName: "دسترسی گروه کاربری", route: "UserAccess" },
        // { routeName: "دسترسی اختصاصی", route: "ExclusiveAccess" },
        { routeName: "شماره گذاری اسناد", route: "Andikator" },
        { routeName: "پوشه بندی آرشیو اداری", route: "Archive" },
        { routeName: "پوشه بندی شخصی", route: "Favorite" },
        { routeName: "طراحی قالب نامه", route: "Home" },
        { routeName: "مدیریت قالب نامه", route: "TemplateManager" },
        { routeName: "بانکها", route: "Banks" },
        { routeName: "سرفصلها", route: "Headlines" },
        { routeName: "حسابهای بانکی", route: "BankAccounts" },
        { routeName: "صندوق ها", route: "Funds" },
      ],
      items: [],
      items1: [
        { icon: "mdi-pencil", active: false },
        { icon: "mdi-pencil", active: false },
        { icon: "mdi-clock-alert-outline", active: false },
        { icon: "mdi-account-check", active: false },
        { icon: "mdi-card-account-phone", active: false },
        { icon: "mdi-purse", active: false },
        { icon: "mdi-account-cash", active: false },
        { icon: "mdi-texture-box", active: false },
        { icon: "mdi-face-agent", active: false },
        { icon: "mdi-cloud-upload", active: false },
        { icon: "mdi-cloud-upload", active: false },
        { icon: "mdi-cloud-upload", active: false },
        { icon: "mdi-cloud-upload", active: false },
        { icon: "mdi-cogs", active: false },
      ],
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"],
      ],
      cruds: [
        ["Create", "mdi-plus-outline"],
        ["Read", "mdi-file-outline"],
        ["Update", "mdi-update"],
        ["Delete", "mdi-delete"],
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getBaseUrl",
      "getUrl",
      "getColor",
      "getMessage",
      "getMenu",
    ]),
    setColor() {
      return this.$store.state.backgroundApp;
    },
    getUserProfile() {
      return this.$store.state.CurrentUser.pic;
    },
    hasToken() {
      return this.$store.state.token;
    },
  },
  created() {
    this.$store.dispatch("setToken", localStorage.getItem("token"));
    this.$store.state.token = localStorage.getItem("token");
    this.$store.state.CurrentUser.userId = localStorage.getItem("userId");
    this.$store.state.CurrentUser.username = localStorage.getItem("username");
    this.$store.state.CurrentUser.name = localStorage.getItem("name");
    this.$store.state.CurrentUser.mobile = localStorage.getItem("mobile");
    this.$store.state.CurrentUser.permissions =
      localStorage.getItem("permissions");
    this.$store.state.CurrentUser.pic = localStorage.getItem("pic")
      ? this.getBaseUrl + "media/userpic/" + localStorage.getItem("pic")
      : "https://randomuser.me/api/portraits/men/85.jpg";
  },
  mounted() {
    if (this.$store.state.token) this.getList();

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setMessage") {
        if (state.message.message != "") {
          this.snackbar = true;
        }
      }
    });
  },
  methods: {
    showM(item) {
      for (const itemMenu of this.items1) {
        itemMenu.active = false;
      }
      item.active = true;
    },
    toggleTab(tab) {},
    getList() {
      this.$store.dispatch("sendListMenu", { url: "menu/list/" });
    },
    removeToken() {
      this.$store.dispatch("logout");
      localStorage.removeItem("tenantFullName");
      this.$router.push({ name: "Login" }).catch(() => {});
      this.$store.commit("setMenu", "");
    },
    goToRoute(item1) {
      let result = this.root.find((item2) => {
        return item2.routeName == item1.title;
      });
      if (result) {
        if (this.$router.currentRoute.name == result.route) {
          return;
        } else {
          this.$router
            .push({
              name: result.route,
              params: { title: item1.title },
            })
            .catch(() => {});
        }
      } else if (
        item1.type == "ArchiveMessage" ||
        item1.type == "FavoriteMessage"
      ) {
        this.$router
          .push({
            name: item1.type,
            params: { title: item1.title, typeNumber: item1.id },
          })
          .catch(() => {});
      }
    },
    onNotificationClick(data) {
      this.$store.commit("removeNotifications", data);
      if (data.url) {
        this.$router.push(data.url).catch(() => {});
      }
    },
  },
  watch: {
    setColor() {
      this.changebackgroundColor = true;
    },
    mini() {
      // this.$store.commit('setMini' , this.mini);
    },
  },
  // Register your listener here.
  sockets: {
    // Equivalent of
    // signalrHubConnection.on('someEvent', (data) => this.someActionWithData(data))
    Notification(data) {
      this.$store.commit("setMessage", {
        message: data.title,
        color: "success",
        timeOut: 3000,
      });
      this.$store.commit("addNotifications", data);
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  background: #eeeeee;
}
.border {
  border: 1px solid black;
}
.round {
  border-top-right-radius: 30px !important;
  // border-top-left-radius: 30px!important;
}
// .a {
//   background-image: url("./assets/image/sidbarcover.png") !important;
//   background-repeat: no-repeat !important;
//   background-size: 100% 250px !important;
// }
.color {
  background: transparent;
}
* {
  font-family: Vazirmatn, sans-serif !important;
}
.my-custom-class .v-list-item__content {
  width: 200px;
}
.v-treeview-node--click {
  color: red !important;
}
.changeColor {
  color: rgb(226, 73, 73) !important;
}
.search ::placeholder {
  font-size: 0.8rem !important;
  color: white !important;
}
.font {
  // font-size: 0.85rem!important;
}
::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.card-one {
  transition: 1s;
}
.card-one:hover {
  z-index: 1;
  transform: scale(1.1);
}
// .custom-link .v-list-item--active .v-list-item__title {
//   color: red; /* رنگ مورد نظر را تغییر دهید */
// }
// .v-list .v-list-item--active .v-icon{
//     color: green!important;
// }
// .v-list .v-list-item--active .v-icon{
//    color: green!important;
// }

.v-application .primary--text {
  color: #101111 !important;
  caret-color: #202020 !important;
}

.v-application--is-rtl .menu .v-list-item__icon:first-child {
  margin-left: 4px;
}
</style>
<style>
#main-menu .v-list .v-list-item--active {
  color: #b5b5b5;
}
</style>
<template>
  <section class="section__register">
    <v-container fluid>
      <v-row class="my-sm-16 my-md-0">
        <v-col
          cols="12"
          md="6"
          lg="4"
          v-if="showRegister"
          class="mx-auto"
          style="margin-top: 7rem"
        >
          <v-card
            class="section__register-body mx-auto rounded-xl elevation-24 px-1"
          >
            <v-row>
              <v-col cols="12" class="text-center">
                <h3>ثبت نام</h3>
              </v-col>
            </v-row>
            <v-card-text>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="نام"
                      hide-details
                      outlined
                      dense
                      v-model="register.fname"
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="نام خانوادگی"
                      hide-details
                      outlined
                      dense
                      v-model="register.lname"
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="نام شرکت"
                      outlined
                      dense
                      v-model="register.companyName"
                      :rules="usernameRule"
                    ></v-text-field>
                    <span> آدرس به صورت </span>
                    <span> {{ showSubDomain }}.dayavo.com </span>
                    <span> در دسترس خواهد بود </span>
                  </v-col>
                  <!-- <v-col cols="12">
                    <v-text-field
                      label="آدرس سایت"
                      outlined
                      dense
                      v-model="register.subDomain"
                      :rules="usernameRule"
                    ></v-text-field>
                    <span> آدرس به صورت </span>
                    <span> {{ showSubDomain }}.dayavo.com </span>
                    <span> در دسترس خواهد بود </span>
                  </v-col> -->
                  <!-- <v-col cols="12">
                    <v-text-field
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      label="رمز"
                      outlined
                      hide-details
                      dense
                      v-model="register.password"
                      @click:append="show1 = !show1"
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col> -->
                  <v-col cols="12">
                    <v-text-field
                      label="شماره موبایل"
                      hide-details
                      outlined
                      dense
                      v-model="register.mobile"
                      :rules="requiredRule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="12" class="px-5">
                  <v-btn
                    :disabled="loading"
                    dark
                    class="success"
                    v-on:click="sendDataRegister"
                    block
                  >
                    <span v-if="!loading"> ثبت نام </span>
                    <v-progress-circular
                      v-if="loading"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-btn>
                </v-col>
                <v-col cols="12" class="text-center">
                  <p v-on:click="goToLogin" class="section__register-btn">
                    ورود
                  </p>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-dialog v-model="dialog" width="400">
          <v-card class="rounded-xl elevation-10 text-center py-5">
            <h3 class="pb-4">ورود کد تایید</h3>
            <v-card-text>
              <v-form ref="formCode">
                <v-text-field
                  label="کد تایید"
                  hide-details
                  outlined
                  dense
                  v-model="code"
                  :rules="requiredRule"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                dark
                class="success"
                v-on:click="sendCode"
                block
                :disabled="loading"
              >
                <span v-if="!loading"> ثبت نام </span>
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </section>
</template>
  
  <script>
import axios from "axios";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";

export default {
  mixins: [myMixin],
  data() {
    return {
      usernameRule: [
        (v) => !!v || "الزامی است",
        (v) => /^[A-Za-z\d -]+$/.test(v) || "باید شامل عدد و حروف انگلیسی باشد",
      ],
      loading: false,
      show1: false,
      show2: false,
      dialog: false,
      showRegister: true,
      showCompleteRegistration: false,
      register: {
        language: 0,
        subDomain: null,
        mobile: null,
        lname: null,
        fname: null,
        password: null,
        link: null,
        companyName: null,
      },
      code: null,
      // completeRegistration: {
      //   CompanyName: null,
      //   Password: null,
      //   Logo: null,
      // },
    };
  },
  created() {
    //for register page
    localStorage.removeItem("token");
  },
  computed: {
    ...mapGetters(["getUrl"]),
    showSubDomain() {
      return this.register.companyName ? this.register.companyName : "xxxxx";
    },
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: "Login" }).catch(() => {});
    },
    sendDataRegister() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let url = window.location.host;
        this.register.Link = url;
        this.register.Utm = this.$route.query.utm;
        axios
          .post(`${this.getUrl}/user/register/`, this.register)
          .then((response) => {
            if (response.data.status == 1 || response.data.status == 3) {
              this.dialog = true;
              this.$store.commit("setMessage", {
                message: response.data.message,
                color: "success",
                timeOut: 3000,
              });
            } else if (response.data.status != 1) {
              this.$store.commit("setMessage", {
                message: response.data.message,
                color: "red",
                timeOut: 3000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    sendCode() {
      if (this.$refs.formCode.validate()) {
        this.loading = true;

        axios
          .post(`${this.getUrl}/user/code/`, {
            Language: 0,
            Mobile: this.register.mobile,
            Code: this.code,
          })
          .then((response) => {
            if (response.data.status == 1) {
              this.dialog = false;
              this.showRegister = false;
              this.$store.dispatch("setToken", response.data.token);
              localStorage.setItem(
                "tenantFullName",
                response.data.tenantFullName
              );
              localStorage.setItem("userId", response.data.userId);
              localStorage.setItem("username", response.data.username);
              localStorage.setItem("pic", response.data.pic);
              localStorage.setItem("mobile", response.data.mobile);
              localStorage.setItem(
                "name",
                `${response.data.fName} ${response.data.lName}`
              );
              localStorage.setItem("permissions", response.data.permissions);

              this.$store.state.CurrentUser.userId = response.data.userId;
              this.$store.state.CurrentUser.username = response.data.username;
              this.$store.state.CurrentUser.mobile = response.data.mobile;
              this.$store.state.CurrentUser.pic = response.data.pic
                ? this.getBaseUrl + "media/userpic/" + response.data.pic
                : "https://randomuser.me/api/portraits/men/85.jpg";
              this.$store.state.CurrentUser.name = `${response.data.fName} ${response.data.lName}`;
              this.$store.state.CurrentUser.permissions =
                response.data.permissions;
              window.open(
                `https://${response.data.companyWebsiteAddress}`,
                "_self"
              );
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.section__register {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/image/login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .section__register-body {
    background: rgba(225, 225, 225, 0.8);
  }
  .section__register-btn {
    transition: 1s;
    &:hover {
      cursor: pointer;
      color: red;
    }
  }
}
</style>
  

<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            :items="getTicketList"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="form">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :rules="nameRules"
                        label="نوع قرارداد"
                        hide-details
                        outlined
                        :items="getorgunitList.records"
                        item-text="orgUnitTitle"
                        item-value="orgUnitId"
                        dense
                        v-model="addData.organUnit"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="موضوع"
                        outlined
                        hide-details
                        dense
                        v-model="addData.subject"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        outlined
                        label="پیام"
                        rows="4"
                        row-height="30"
                        v-model="addData.message"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input
                        label="پیوست"
                        ref="fileInput"
                        v-model="image"
                        accept="image/*"
                        @change="uploadImage"
                        :clearable="false"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" v-if="addData.cover">
                      <v-img
                        class="mx-auto"
                        min-width="100"
                        min-height="100"
                        ref="image"
                        max-width="200"
                        max-height="200"
                        :src="`https://crmapi.dayavo.com/${addData.cover}`"
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :rules="nameRules"
                        label="نوع قرارداد"
                        hide-details
                        outlined
                        :items="getorgunitList.records"
                        item-text="orgUnitTitle"
                        item-value="orgUnitId"
                        dense
                        v-model="editData.data.organUnit"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="موضوع"
                        outlined
                        hide-details
                        dense
                        v-model="editData.data.subject"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        outlined
                        label="پیام"
                        rows="4"
                        row-height="30"
                        v-model="editData.message"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-file-input
                        label="پیوست"
                        ref="fileEditInput"
                        v-model="image"
                        accept="image/*"
                        @change="uploadEditImage"
                        :clearable="false"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-card
                    v-for="(message, index) in messagesArray"
                    :key="index"
                    class="mt-8 px-3"
                  >
                    <v-row>
                      <v-col cols="12" md="6">{{ message.date }}</v-col>
                      <v-col cols="12" md="6">{{
                        getTitle(message.manFk)
                      }}</v-col>
                      <!-- <v-col cols="12" md="2" v-if="message.Attachment">
                      <v-btn icon><v-icon>mdi-paperclip</v-icon></v-btn>
                      </v-col> -->
                      <v-col cols="12" v-if="message.attachment">
                        <v-img
                          class="mx-auto"
                          min-width="100"
                          min-height="100"
                          ref="image"
                          max-width="200"
                          max-height="200"
                          :src="`https://crmapi.dayavo.com/${message.attachment}`"
                        ></v-img>
                      </v-col>
                      <v-col cols="12">{{ message.message }}</v-col>
                    </v-row>
                  </v-card>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { myMixin } from "../../../mixins.js";
import { mapGetters } from "vuex";
export default {
  name: "Banks",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      searchTest: "",
      image: null,
      messagesArray: null,
      addData: {
        url: "ticket/create/",
        organUnit: null,
        subject: null,
        cover: null,
        message: null,
        test: this.$refs,
      },
      editData: {
        url: "ticket/update/",
        message: null,
        data: {
          organUnit: null,
          subject: null,
          cover: null,
        },
      },
      removeData: { url: "ticket/delete/" },
      headers: [
        {
          text: "فرستنده",
          align: "start",
          value: "senderName",
        },
        {
          text: "موضوع",
          align: "start",
          value: "subject",
        },
        {
          text: "دپارتمان",
          align: "start",
          value: "organUnitName",
        },
        {
          text: "وضعیت",
          align: "start",
          value: "statusText",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getTicketList",
      "getorgunitList",
      "getPersonList",
      "getUrl",
    ]),
  },
  created() {
    this.getTickets();
    this.getorgunit();
    this.getPerson();
  },
  methods: {
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "ticket/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "ticket/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getTickets() {
      this.$store.dispatch("sendList", {
        url: "ticket/list/",
        page: 1,
        Phrase: "",
      });
    },
    getorgunit() {
      this.$store.dispatch("sendList", {
        url: "orgunit/list/",
        page: 1,
        Phrase: "",
      });
    },
    uploadImage(e) {
      // خواندن فایل عکس به صورت باینری

      const fileInput = this.$refs.fileInput.$refs.input;
      // console.log(e);
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      // تبدیل رشته عکس به رشته Base64
      reader.onload = () => {
        const base64Image = btoa(reader.result);

        // تعیین بدنه درخواست
        const data = {
          File: base64Image,
          Language: 0,
        };

        // ارسال درخواست به سرور
        axios
          .post(`${this.getUrl}/ticket/coverupload/`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.addData.Cover = response.data.fullPath;
            // console.log("خطا در آپلود عکس:", response);
          })
          .catch((error) => {
            console.error("خطا در آپلود عکس:", error.message);
          });
      };
    },
    uploadEditImage(e) {
      // خواندن فایل عکس به صورت باینری

      const fileInput = this.$refs.fileEditInput.$refs.input;
      // console.log(e);
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      // تبدیل رشته عکس به رشته Base64
      reader.onload = () => {
        const base64Image = btoa(reader.result);

        // تعیین بدنه درخواست
        const data = {
          Cover: base64Image,
          Language: 0,
        };

        // ارسال درخواست به سرور
        axios
          .post(`${this.getUrl}/project/coverupload/`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.editData.data.Cover = response.data.fullPath;
            // console.log("خطا در آپلود عکس:", response);
          })
          .catch((error) => {
            console.error("خطا در آپلود عکس:", error.message);
          });
      };
    },
    getData(item) {
      // console.log(item, "----------------");
      axios
        .get(`${this.getUrl}/ticket/get/`, {
          params: {
            Id: item.ticketId,
            Language: 0,
          },
        })
        .then((response) => {
          // console.log(response);
          this.editData.data = response.data.record;
          this.messagesArray = response.data.messages;
          this.editData.data.test = this.$refs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPerson() {
      this.$store.dispatch("sendList", {
        url: "person/list/",
        page: 1,
        Phrase: "",
      });
    },
    getTitle(number) {
      let result = this.getPersonList.records.find((item) => {
        return item.manCompanyId == number;
      });
      return result.fullName;
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>

<template>
  <v-card dark style="background-color: transparent">
    <v-list dense light style="background-color: #f3f3f3">
      <v-subheader>
        <v-btn
          block
          dark
          :color="setColor"
          @click="
            $router.push({
              name: 'Letter',
              params: {
                create: true,
                pagetype: 'AllMessage',
                messagetype: 4,
                id: 0,
              },
            })
          "
        >
          نامه جدید
        </v-btn>
      </v-subheader>
      <v-list-group
        color="secondary"
        :value="false"
        style="font-size: 0.6rem !important"
        dense
        id="send-shortkey"
      >
        <template v-slot:activator>
          <v-icon class="ml-2">mdi-send-outline</v-icon>
          <v-list-item-title style="font-size: 0.7rem !important">
            ارسال شده ها
          </v-list-item-title>
        </template>

        <v-list-item>
          <v-list-item-title
            style="font-size: 0.7rem !important"
            @click="
              $router.push({
                name: 'DomesticLetters',
              })
            "
          >
            نامه های داخلی
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title
            style="font-size: 0.7rem !important"
            @click="
              $router.push({
                name: 'IncomingLetters',
              })
            "
          >
            نامه های وارده
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            style="font-size: 0.7rem !important"
            @click="
              $router.push({
                name: 'LettersIssued',
              })
            "
          >
            نامه های صادره
          </v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>
      <v-list-item
        @click="
          $router.push({
            name: 'MyMessage',
          })
        "
      >
        <v-list-item-icon>
          <v-icon>mdi-account-eye</v-icon>
        </v-list-item-icon>
        <v-list-item-title style="font-size: 0.7rem !important">
          کارتابل نامه های من
        </v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item
        v-if="this.$store.state.CurrentUser.permissions.includes('AllMessages')"
        @click="
          $router.push({
            name: 'AllMessage',
          })
        "
      >
        <v-list-item-icon>
          <v-icon>mdi-email-newsletter</v-icon>
        </v-list-item-icon>
        <v-list-item-title style="font-size: 0.7rem !important">
          کلیه نامه ها
        </v-list-item-title>
      </v-list-item>

      <v-divider
        v-if="this.$store.state.CurrentUser.permissions.includes('AllMessages')"
      ></v-divider>
      <v-list-item
        @click="
          $router.push({
            name: 'DraftMessage',
          })
        "
      >
        <v-list-item-icon>
          <v-icon>mdi-draw-pen</v-icon>
        </v-list-item-icon>
        <v-list-item-title style="font-size: 0.7rem !important">
          پیش نویس نامه ها
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: ["setColor"],
};
</script>

<style  scoped>
.v-list--dense .v-list-item .v-list-item__title {
  cursor: pointer;
}
.v-application--is-rtl .v-list-item__action:first-child,
.v-application--is-rtl .v-list-item__icon:first-child {
  margin-left: 8px;
}
</style>
<style>
#send-shortkey .v-list-group__header__append-icon {
  min-width: 0px !important;
}
</style>
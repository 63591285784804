<template>
  <v-card flat tile>
    <v-card-title>
      <v-row> </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col cols="12" lg="12">
            <p>نوع ارتباط</p>
            <v-radio-group
              v-model="relatedTypeModel"
              row
              :rules="requiredMultiRule"
            >
              <v-radio label="پیرو" value="RdFollw"></v-radio>
              <v-radio label="ارجاء" value="RdReferred"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-col cols="12" lg="12" class="section__home-data px-5">
          <v-card class="rounded-lg pt-3">
            <v-data-table
              :headers="headers"
              :items="sendList?.records"
              item-value="messageId"
              item-key="messageId"
              v-model="selectedItems"
              dense
              show-select
              :fixed-header="true"
              :footer-props="{ itemsPerPageOptions: [10] }"
              :items-per-page="10"
              :options.sync="options"
              :server-items-length="sendList?.totalCount ?? 10"
              :loading="this.loading"
            >
              <template v-slot:top>
                <v-row>
                  <v-col cols="12">
                    <v-toolbar dense flat>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            dense
                            outlined
                            v-model="search"
                            label="جستجو"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-toolbar>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-form>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn v-on:click="confirm" color="success">ثبت</v-btn>
      <v-btn v-on:click="cancle" class="mr-4">بستن</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";

export default {
  mixins: [myMixin],
  props: ["headers", "sendList", "loading"],
  data() {
    return {
      search: "",
      expanded: [],
      selectedItems: [],
      relatedTypeModel: [],
      options: {},
    };
  },
  computed: {
    ...mapGetters(["getPageListSetting"]),
  },
  methods: {
    confirm() {
      if (this.$refs.form.validate()) {
        let follow = this.relatedTypeModel == "RdFollw";
        this.selectedItems.forEach(function (element) {
          follow ? (element.follow = true) : (element.follow = false);
        });
        this.$emit("selectChanged", this.selectedItems);
      }
    },
    cancle() {
      this.$emit("cancle");
    },
  },
  watch: {
    options: {
      handler() {
        this.$emit("pageChanged", this.options);
      },
      deep: true,
    },
    search: {
      handler() {
        this.options.page = 1;
        this.$emit("search", this.search);
      },
    },
  },
};
</script>

<style>
</style>
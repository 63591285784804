<template>
  <v-col cols="12" sm="12" lg="5" class="pb-0">
    <v-row>
      <v-col cols="6">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              min-height="130"
              v-bind="attrs"
              v-on="on"
              max-height="140"
              color="blue lighten-2"
              dark
              class="card-one rounded-lg"
              @click="
                $router.push({
                  name: 'UnReadMessage',
                })
              "
            >
              <v-container fluid>
                <v-row style="cursor: pointer">
                  <v-col cols="12" class="text-h4">
                    <span style="font-size: 1rem">خوانده نشده</span></v-col
                  >
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <span
                          style="font-size: 1.2rem"
                          class="text-left text-h4"
                          >{{ boxData.unReadMessageCount }}</span
                        >
                      </v-col>
                      <v-col cols="3" class="text-end">
                        <v-icon large v-bind="attrs" v-on="on"
                          >mdi-message-text-clock-outline</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </template>
          <span>خوانده نشده</span>
        </v-tooltip>
      </v-col>

      <v-col cols="6">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              min-height="130"
              max-height="140"
              color="#A0CB32"
              dark
              class="card-one rounded-lg"
              v-bind="attrs"
              v-on="on"
              @click="
                $router.push({
                  name: 'AnswerRequest',
                })
              "
            >
              <v-container fluid>
                <v-row style="cursor: pointer">
                  <v-col cols="12" class="text-h4">
                    <span style="font-size: 1rem">نیاز به پاسخ</span></v-col
                  >
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <span
                          style="font-size: 1.2rem"
                          class="text-left text-h4"
                          >{{ boxData.answerRequestCount }}</span
                        >
                      </v-col>
                      <v-col cols="3" class="text-end">
                        <v-icon large v-bind="attrs" v-on="on"
                          >mdi-chat-outline</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </template>
          <span>نیاز به پاسخ</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              min-height="130"
              v-bind="attrs"
              v-on="on"
              max-height="140"
              color="#FA9F1B"
              dark
              class="card-one rounded-lg"
              @click="
                $router.push({
                  name: 'DraftMessage',
                })
              "
            >
              <v-container fluid>
                <v-row style="cursor: pointer">
                  <v-col cols="12" class="text-h4">
                    <span style="font-size: 1rem">پیش نویس</span></v-col
                  >
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <span
                          style="font-size: 1.2rem"
                          class="text-left text-h4"
                          >{{ boxData.draftMessageCount }}</span
                        >
                      </v-col>
                      <v-col cols="3" class="text-end">
                        <v-icon large v-bind="attrs" v-on="on"
                          >mdi-message-draw</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </template>
          <span>پیش نویس</span>
        </v-tooltip>
      </v-col>

      <v-col cols="6">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              min-height="130"
              v-bind="attrs"
              v-on="on"
              max-height="140"
              color="#AB46BC"
              dark
              class="card-one rounded-lg"
              @click="
                $router.push({
                  name: 'Tasks',
                })
              "
            >
              <v-container fluid>
                <v-row style="cursor: pointer">
                  <v-col cols="12" class="text-h4">
                    <span style="font-size: 1rem">تسک در جریان</span></v-col
                  >
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="8">
                        <span
                          style="font-size: 1.2rem"
                          class="text-left text-h4"
                          >{{ boxData.unCompleteTaskCount }}</span
                        >
                      </v-col>
                      <v-col cols="3" class="text-end">
                        <v-icon large v-bind="attrs" v-on="on"
                          >mdi-alert-box-outline</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </template>
          <span>تسک های در جریان</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "HomeBox",

  data() {
    return {
      boxData: {
        unReadMessageCount: 0,
        draftMessageCount: 0,
        answerRequestCount: 0,
        unCompleteTaskCount: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["getUrl"]),
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      axios
        .get(`${this.getUrl}/home/getbox/`, {
          params: {
            Language: 0,
          },
        })
        .then((response) => {
          this.boxData.unReadMessageCount = response.data.unReadMessageCount;
          this.boxData.draftMessageCount = response.data.draftMessageCount;
          this.boxData.answerRequestCount = response.data.answerRequestCount;
          this.boxData.unCompleteTaskCount = response.data.unCompleteTaskCount;
        });
    },
  },
};
</script>

<style>
</style>
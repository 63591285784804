var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","lg":"5"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({staticClass:"card-one rounded-lg",attrs:{"min-height":"130","max-height":"140","color":"blue lighten-2","dark":""},on:{"click":function($event){return _vm.$router.push({
                name: 'UnReadMessage',
              })}}},'v-card',attrs,false),on),[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"cursor":"pointer"}},[_c('v-col',{staticClass:"text-h4",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"1rem"}},[_vm._v("خوانده نشده")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('span',{staticClass:"text-left text-h4",staticStyle:{"font-size":"1.2rem"}},[_vm._v(_vm._s(_vm.boxData.unReadMessageCount))])]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"3"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-message-text-clock-outline")])],1)],1)],1)],1)],1)],1)]}}])},[_c('span',[_vm._v("خوانده نشده")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({staticClass:"card-one rounded-lg",attrs:{"min-height":"130","max-height":"140","color":"#A0CB32","dark":""},on:{"click":function($event){return _vm.$router.push({
                name: 'AnswerRequest',
              })}}},'v-card',attrs,false),on),[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"cursor":"pointer"}},[_c('v-col',{staticClass:"text-h4",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"1rem"}},[_vm._v("نیاز به پاسخ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('span',{staticClass:"text-left text-h4",staticStyle:{"font-size":"1.2rem"}},[_vm._v(_vm._s(_vm.boxData.answerRequestCount))])]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"3"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-chat-outline")])],1)],1)],1)],1)],1)],1)]}}])},[_c('span',[_vm._v("نیاز به پاسخ")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({staticClass:"card-one rounded-lg",attrs:{"min-height":"130","max-height":"140","color":"#FA9F1B","dark":""},on:{"click":function($event){return _vm.$router.push({
                name: 'DraftMessage',
              })}}},'v-card',attrs,false),on),[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"cursor":"pointer"}},[_c('v-col',{staticClass:"text-h4",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"1rem"}},[_vm._v("پیش نویس")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('span',{staticClass:"text-left text-h4",staticStyle:{"font-size":"1.2rem"}},[_vm._v(_vm._s(_vm.boxData.draftMessageCount))])]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"3"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-message-draw")])],1)],1)],1)],1)],1)],1)]}}])},[_c('span',[_vm._v("پیش نویس")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({staticClass:"card-one rounded-lg",attrs:{"min-height":"130","max-height":"140","color":"#AB46BC","dark":""},on:{"click":function($event){return _vm.$router.push({
                name: 'Tasks',
              })}}},'v-card',attrs,false),on),[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"cursor":"pointer"}},[_c('v-col',{staticClass:"text-h4",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"1rem"}},[_vm._v("تسک در جریان")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('span',{staticClass:"text-left text-h4",staticStyle:{"font-size":"1.2rem"}},[_vm._v(_vm._s(_vm.boxData.unCompleteTaskCount))])]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"3"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-box-outline")])],1)],1)],1)],1)],1)],1)]}}])},[_c('span',[_vm._v("تسک های در جریان")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            :items="gecompanyList"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="form">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان شرکت"
                        outlined
                        dense
                        hide-details
                        v-model="addData.companyName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        label="کد اقتصادی"
                        outlined
                        dense
                        hide-details
                        v-model="addData.economicalCode"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        label="شناسه ملی"
                        outlined
                        dense
                        hide-details
                        v-model="addData.nationalId"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-select
                        label="شرکت مادر"
                        outlined
                        :items="gecompanyList.records"
                        item-text="companyName"
                        item-value="companyId"
                        dense
                        hide-details
                        v-model="addData.parentCompanyFk"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        :rules="numberRules"
                        label="شماره تماس"
                        outlined
                        dense
                        hide-details
                        v-model="addData.phone"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        :rules="numberRules"
                        label="شماره موبایل"
                        outlined
                        dense
                        hide-details
                        v-model="addData.mobile"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        :rules="emailRules"
                        label="آدرس ایمیل"
                        outlined
                        dense
                        hide-details
                        v-model="addData.email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        label="کدپستی"
                        outlined
                        dense
                        hide-details
                        v-model="addData.postalCode"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        :rules="numberRules"
                        label="شماره فکس"
                        outlined
                        dense
                        hide-details
                        v-model="addData.fax"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="آدرس"
                        outlined
                        dense
                        hide-details
                        v-model="addData.address"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        label="فعال"
                        v-model="addData.isActive"
                        dense
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان شرکت"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.companyName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        label="کد اقتصادی"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.economicalCode"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        label="شناسه ملی"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.nationalId"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-select
                        label="شرکت مادر"
                        outlined
                        :items="gecompanyList.records"
                        item-text="companyName"
                        item-value="companyId"
                        dense
                        hide-details
                        v-model="editData.data.parentCompanyFk"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        :rules="numberRules"
                        label="شماره تماس"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.phone"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        :rules="numberRules"
                        label="شماره موبایل"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.mobile"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        :rules="emailRules"
                        label="آدرس ایمیل"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        label="کدپستی"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.postalCode"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-text-field
                        :rules="numberRules"
                        label="شماره فکس"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.fax"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="آدرس"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.address"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        label="فعال"
                        v-model="editData.data.isActive"
                        dense
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import MenuLeft from "../../components/MenuLeft.vue";
import AccountingSystem from "../../components/AccountingSystem.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
export default {
  name: "Banks",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      searchTest: "",
      addData: {
        url: "company/create/",
        economicalCode: null,
        nationalId: null,
        companyName: null,
        parentCompanyFk: null,
        email: null,
        phone: null,
        mobile: null,
        fax: null,
        postalCode: null,
        address: null,
        isActive: true,
        test: this.$refs,
      },
      editData: {
        url: "company/update/",
        data: {
          economicalCode: null,
          nationalId: null,
          companyName: null,
          parentCompanyFk: null,
          email: null,
          phone: null,
          mobile: null,
          fax: null,
          postalCode: null,
          address: null,
          isActive: true,
        },
      },
      removeData: { url: "company/delete/" },
      headers: [
        {
          text: "نام",
          align: "center",
          value: "companyName",
        },
        {
          text: "ایمیل",
          align: "center",
          value: "email",
        },
        {
          text: "موبایل",
          align: "center",
          value: "mobile",
        },
        {
          text: "فعال",
          align: "center",
          value: "isActive",
        },
        {
          text: "کد ملی",
          align: "center",
          value: "nationalId",
        },
        {
          text: "شماره تماس",
          align: "center",
          value: "phone",
        },
        // {
        //   text: "شرکت مادر",
        //   align: "center",
        //   value: "parentCompanyName",
        // },
        {
          text: "کد پستی",
          align: "center",
          value: "postalCode",
        },

        {
          text: "فکس",
          align: "center",
          value: "fax",
        },
        {
          text: "آدرس",
          align: "center",
          value: "address",
        },

        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["gecompanyList", "getUrl"]),
  },
  created() {
    this.getCompany();
  },
  methods: {
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "company/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "company/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getCompany() {
      this.$store.dispatch("sendList", {
        url: "company/list/",
        page: 1,
        Phrase: "",
      });
    },
    getData(item) {
      axios
        .get(`${this.getUrl}/company/get/`, {
          params: {
            Id: item.companyId,
            Language: 0,
          },
        })
        .then((response) => {
          this.editData.data = response.data.record;
          this.editData.data.test = this.$refs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>

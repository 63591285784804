<template>
  <div
    v-show="showList(task.checkList)"
    class="task-checklist-in-detail"
    @click.stop
  >
    <div class="progress">
      <v-progress-linear :value="completion" />
    </div>

    <v-simple-table
      v-if="task.checkList && task.checkList.length > 0"
      class="tasks-wrapper elevation-1"
    >
      <tbody v-sortable-list="moveItem">
        <tr v-for="checkItem in task.checkList" :key="checkItem._id">
          <td class="check">
            <div class="checkbox">
              <div class="pretty p-svg p-curve">
                <input
                  v-model="checkItem.checked"
                  type="checkbox"
                  @change="toggleCheckItem(checkItem)"
                  @click="(e) => e.stopPropagation()"
                />
                <div class="state p-primary">
                  <svg class="svg svg-icon" viewBox="0 0 20 20">
                    <!-- eslint-disable -->
                    <path
                      d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                      style="stroke: white; fill: white"
                    ></path>
                    <!-- eslint-enable -->
                  </svg>
                  <label />
                </div>
              </div>
            </div>
          </td>
          <td class="edit" @click="startEditCheckItem(checkItem)">
            <template v-if="!isCheckItemEdited(checkItem)">
              <span v-html="linkifyHtml(checkItem.name)" />
            </template>
            <template v-if="isCheckItemEdited(checkItem)">
              <v-textarea
                v-model="checkItem.name"
                autofocus
                outlined
                auto-grow
                :rows="3"
                solo
                @keyup.esc="cancelUpdateCheckItem(checkItem)"
                @keydown.shift.enter="updateCheckItem(checkItem)"
              />
              <v-btn icon text @click.native.stop="updateCheckItem(checkItem)">
                <v-icon color="green"> mdi-check-circle </v-icon>
              </v-btn>

              <v-btn
                icon
                text
                @click.native.stop="cancelUpdateCheckItem(checkItem)"
              >
                <v-icon color="red"> mdi-close-circle </v-icon>
              </v-btn>
            </template>
          </td>
          <td class="table-actions">
            <v-icon style="cursor: row-resize" class="sortHandle">
              mdi-drag-vertical
            </v-icon>
          </td>
          <td class="table-actions">
            <v-menu bottom left class="menu" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>{{ $t("Move") }}</v-list-item-title>
                  <v-list-item-action>
                    <v-btn icon small @click="moveUp(checkItem)">
                      <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn icon small @click="moveDown(checkItem)">
                      <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>{{ $t("Delete") }}</v-list-item-title>
                  <v-list-item-action>
                    <v-btn icon small @click="deleteItem(checkItem)">
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <v-text-field
      ref="newItem"
      v-model="item"
      class="add-item"
      append-icon="mdi-send"
      :label="$t('New item')"
      @keyup.enter="addItem"
      @click:append="addItem"
    />
  </div>
</template>

<script>
import "moment/locale/fr";
import Sortable from "sortablejs";
import TextRenderingMixin from "@/latelier/imports/ui/mixins/TextRenderingMixin.js";
import axios from "axios";
import { mapState, mapGetters } from "vuex";

export default {
  name: "TaskChecklistInDetail",
  directives: {
    sortableList: {
      bind(el, binding) {
        const options = {
          handle: ".sortHandle",
          animation: 150,
          forceFallback: true,
          fallbackTolerance: 4,
          touchStartThreshold: 4,
          onUpdate: function (event) {
            binding.value(event);
          },
        };
        Sortable.create(el, options);
      },
    },
  },
  mixins: [TextRenderingMixin],
  props: {
    hideIfEmpty: {
      value: false,
    },
    task: {
      default: () => {},
    },
  },
  data() {
    return {
      editNewItem: false,
      item: "",
      completion: 0,
      selectedCheckItem: null,
      savedName: null,
    };
  },
  watch: {
    task: {
      immediate: true,
      handler(task) {
        this.calculateProgress(task);
      },
    },
  },
  computed: {
    ...mapGetters(["getUrl"]),
  },
  methods: {
    showList(checkList) {
      if (this.hideIfEmpty && !this.hasItems(checkList)) {
        return false;
      }
      return true;
    },

    hasItems(checkList) {
      return checkList && checkList.length > 0;
    },

    addItem() {
      this.editNewItem = false;
      // Meteor.call(
      //   "tasks.addChecklistItem",
      //   this.task._id,
      //   this.item,
      //   (error) => {
      //     if (!error) {
      //       this.item = "";
      //     }
      //   }
      // );
      axios
        .post(`${this.getUrl}/taskcheckitem/create/`, {
          Language: 0,
          name: this.item,
          taskItemId: this.task.id,
        })
        .then((data) => {
          if (data.data.status == 1) {
            this.$store.commit("addTaskcheckItem", data.data.record);
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
            });
          } else if (data.data.status == 0) {
            this.$store.commit("setMessage", {
              message: data.data.message,
              color: "red",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    deleteItem(item) {
      this.$confirm(this.$t("Delete element?"), {
        title: this.$t("Confirm"),
        cancelText: this.$t("Cancel"),
        confirmText: this.$t("Delete"),
      }).then((res) => {
        if (res) {
          //Meteor.call("tasks.removeChecklistItem", this.task._id, item._id);
          axios
            .delete(`${this.getUrl}/taskCheckitem/delete/`, {
              data: {
                Id: item.id,
                Language: 0,
              },
            })
            .then((data) => {
              if (data.data.status == 1) {
                this.$store.commit("removeTaskcheckItem", {
                  taskItemId: this.task.id,
                  id: item.id,
                });
                this.$store.commit("setMessage", {
                  message: "درخواست با موفقیت انجام شد",
                  color: "success",
                  timeOut: 2000,
                });
              } else if (data.data.status == 0) {
                this.$store.commit("setMessage", {
                  message: data.data.message,
                  color: "red",
                  timeOut: 2000,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {});
        }
      });
    },
    calculateProgress(task) {
      if (!task.checkList) {
        this.completion = 0;
        return;
      }
      const totalItems = task.checkList.length;
      let completedItems = 0;
      task.checkList.forEach((item) => {
        if (item.checked) {
          completedItems += 1;
        }
      });
      this.completion = 100 * (completedItems / totalItems);
    },
    toggleCheckItem(item) {
      // Meteor.call(
      //   "tasks.toggleCheckItem",
      //   this.task._id,
      //   item._id,
      //   item.checked
      // );
      axios
        .put(`${this.getUrl}/taskcheckitem/toggle/`, {
          Language: 0,
          id: item.id,
          checked: item.checked,
        })
        .then((data) => {
          if (data.data.status == 1) {
            this.calculateProgress(this.task);
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
            });
          } else if (data.data.status == 0) {
            this.$store.commit("setMessage", {
              message: data.data.message,
              color: "red",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    cancelAddItem() {
      this.editNewItem = false;
    },

    convertToTask(e, item) {
      if (e) {
        e.stopPropagation();
      }
      this.$confirm(this.$t("Convert element to task?"), {
        title: this.$t("Confirm"),
        cancelText: this.$t("Cancel"),
        confirmText: this.$t("Convert"),
      }).then((res) => {
        if (res) {
          //Meteor.call("tasks.convertItemToTask", this.task._id, item._id);
          axios
            .post(`${this.getUrl}/taskcheckitem/convertitemtotask/`, {
              Language: 0,
              id: item.id,
            })
            .then((data) => {
              if (data.data.status == 1) {
                this.$store.commit("removeTaskcheckItem", {
                  taskItemId: this.task.id,
                  id: item.id,
                });
                this.$store.commit("addTaskItems", data.data.taskItem);

                this.$store.commit("setMessage", {
                  message: "درخواست با موفقیت انجام شد",
                  color: "success",
                });
              } else if (data.data.status == 0) {
                this.$store.commit("setMessage", {
                  message: data.data.message,
                  color: "red",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {});
        }
      });
    },

    isCheckItemEdited(item) {
      if (!this.selectedCheckItem) {
        return false;
      }
      return item.id === this.selectedCheckItem.id;
    },

    startEditCheckItem(item) {
      if (this.isCheckItemEdited(item)) {
        return;
      }
      this.selectedCheckItem = item;
      this.savedName = item.name;
    },

    cancelUpdateCheckItem(item) {
      item.name = this.savedName;
      this.selectedCheckItem = null;
    },

    updateCheckItem(item) {
      if (!this.isCheckItemEdited(item)) {
        return;
      }
      //Meteor.call("tasks.updateCheckListItem", this.task._id, item);
      axios
        .put(`${this.getUrl}/taskcheckitem/updatename/`, {
          Language: 0,
          id: item.id,
          name: item.name,
        })
        .then((data) => {
          if (data.data.status == 1) {
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
            });
          } else if (data.data.status == 0) {
            this.$store.commit("setMessage", {
              message: data.data.message,
              color: "red",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});

      this.selectedCheckItem = null;
    },

    moveItem({ oldIndex, newIndex }) {
      const moved = this.task.checkList.splice(oldIndex, 1)[0];
      this.task.checkList.splice(newIndex, 0, moved);
      Meteor.call("tasks.updateCheckList", this.task._id, this.task.checkList);
    },

    moveUp(item) {
      const oldIndex = this.task.checkList.findIndex(
        (anItem) => anItem.id === item.id
      );
      if (oldIndex === 0) {
        return;
      }
      const newIndex = oldIndex - 1;
      this.moveItem({ oldIndex, newIndex });
    },

    moveDown(item) {
      const oldIndex = this.task.checkList.findIndex(
        (anItem) => anItem.id === item.id
      );
      if (oldIndex === this.task.checkList.length - 1) {
        return;
      }
      const newIndex = oldIndex + 1;
      this.moveItem({ oldIndex, newIndex });
    },

    moveItem(oldIndex, newIndex) {
      [this.task.checkList[oldIndex], this.task.checkList[newIndex]] = [
        this.task.checkList[newIndex],
        this.task.checkList[oldIndex],
      ];
      //Meteor.call("tasks.updateCheckList", this.task._id, this.task.checkList);
    },
  },
};
</script>

<style scoped>
.task-checklist-in-detail {
  margin: 12px;
  padding: 12px;
  max-width: 800px;
  margin: 0 auto;
}

.tasks-wrapper {
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 4px;
}

.icons {
  border: 2px solid black;
}
.add-item {
  margin-left: 8px;
  margin-right: 8px;
}
.add-item label {
  font-size: 13px;
}

.edit {
  width: 100%;
}

.checkbox {
  font-size: 13px;
}
.check {
  width: 48px;
  padding-right: 0;
}

.table-actions {
  padding: 0;
}

.pretty {
  display: inline-block;
  line-height: 1;
  margin-right: 1em;
  position: relative;
  white-space: nowrap;
}
.pretty input {
  cursor: pointer;
  height: 100%;
  left: 0;
  margin: 0;
  min-width: 1em;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.pretty * {
  box-sizing: border-box;
}

.pretty input:checked ~ .state.p-primary .icon,
.pretty input:checked ~ .state.p-primary .svg,
.pretty.p-toggle .state.p-primary .icon,
.pretty.p-toggle .state.p-primary .svg {
  stroke: #fff;
  color: #fff;
}
.pretty .state label {
  display: inline-block;
  font-weight: 400;
  margin: 0;
  min-width: calc(1em + 2px);
  position: static;
  text-indent: 1.5em;
}
.pretty.p-curve .state label:before {
  border-radius: 20%;
}
.pretty .state label:before {
  border-color: #bdc3c7;
}
.state label:before {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
  box-sizing: border-box;
  content: "";
  display: block;
  height: calc(1em + 2px);
  left: 0;
  position: absolute;
  top: calc(-65% + 1em);
  width: calc(1em + 2px);
  z-index: 0;
}
.pretty input:checked ~ .state.p-primary label:after,
.pretty.p-toggle .state.p-primary label:after {
  background-color: #428bca !important;
}
.pretty.p-curve .state label:after,
.pretty.p-curve .state label:before {
  border-radius: 20%;
}
.pretty .state label:after,
.pretty .state label:before {
  background-color: #ffffff;
  border: 1px solid #9d9d9d;
  border-radius: 0;
  box-sizing: border-box;
  content: "";
  display: block;
  height: calc(1em + 2px);
  left: 0;
  position: absolute;
  top: 15%;
  width: calc(1em + 2px);
  z-index: 0;
}
</style>

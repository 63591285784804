export default {
  getBaseUrl(state) {
    return process.env.VUE_APP_API_BASE_URL;
  },
  getUrl(state) {
    return process.env.VUE_APP_API_BASE_URL + "api";
  },
  getBankList(state) {
    return state.BankList;
  },
  getAccountsList(state) {
    return state.Accounts;
  },
  getEceList(state) {
    return state.Ece;
  },
  getOnlineuserList(state) {
    return state.Onlineuser;
  },
  getEceemails(state) {
    return state.Eceemails;
  },
  getEceReceiversList(state) {
    return state.EceReceivers;
  },
  getusergroupmembersList(state) {
    return state.usergroupmembers;
  },
  getDomainList(state) {
    return state.DomainList;
  },
  getBankAccountList(state) {
    return state.BankAccount;
  },
  getFundsList(state) {
    return state.Funds;
  },
  getAndikatorsList(state) {
    return state.Andikators;
  },
  getReciveMethodList(state) {
    return state.ReciveMethod;
  },
  getcustomergroupList(state) {
    return state.customergroup;
  },
  getorgpostList(state) {
    return state.orgpost;
  },
  getorgunitList(state) {
    return state.orgunit;
  },
  gecompanyList(state) {
    return state.company;
  },
  getblogList(state) {
    return state.blog;
  },
  getBlogcategoryList(state) {
    return state.Blogcategory;
  },
  getProjectList(state) {
    return state.ProjectList;
  },
  getProjectgroup(state) {
    return state.Projectgroup;
  },
  getContractList(state) {
    return state.Contract;
  },
  getContractpaymentList(state) {
    return state.Contractpayment;
  },
  getContractSupplementList(state) {
    return state.ContractSupplement;
  },
  getContractActionList(state) {
    return state.ContractAction;
  },
  getCustomerContactList(state) {
    return state.CustomerContact;
  },
  getFavoritePersons(state) {
    return state.FavoritePersons;
  },
  getContractgroupList(state) {
    return state.Contractgroup;
  },
  getDocumentList(state) {
    return state.Document;
  },
  getPersonList(state) {
    return state.Person;
  },
  getCustomerList(state) {
    return state.Customer;
  },
  getColor(state) {
    return state.backgroundApp;
  },
  getInvoiceList(state) {
    return state.Invoice;
  },
  getTicketList(state) {
    return state.Tickets;
  },
  getPlanList(state) {
    return state.Plans;
  },
  getArchiveList(state) {
    return state.Archive;
  },
  getFavoriteList(state) {
    return state.Favorite;
  },
  getPageactionList(state) {
    return state.Pageaction;
  },
  getSystemList(state) {
    return state.System;
  },
  getUsergroupmemberList(state) {
    return state.Usergroupmember;
  },
  getUsergroupList(state) {
    return state.usergroup;
  },
  getTemplateList(state) {
    return state.Template;
  },
  getLinksList(state) {
    return state.Links;
  },
  getLoading(state) {
    return state.loading;
  },
  getMessage(state) {
    return state.message;
  },
  getMenu(state) {
    return state.menu;
  },
  getMini(state) {
    return state.mini;
  },
  getPageListSetting(state) {
    return state.pageListSetting;
  },

  // latelier
  isConnected: (state) => true, //Boolean(state.currentUserId),

  getSelectedTask(state) {
    return state.selectedTask;
  },
  getTaskList(state) {
    return state.taskList;
  },
  getTaskItems(state) {
    return state.taskItems;
  },
};

<template>
  <v-col cols="12" class="pb-0">
    <v-list dense>
      <template v-for="(item, index) in this.desserts1">
        <v-list-item :key="item.name">
          <v-list-item-avatar color="grey lighten-3">
            <img
              v-if="item.createdUserPic"
              :src="getBaseUrl + 'media/userpic/' + item.createdUserPic"
            />
            <v-icon color="grey lighten-1" v-else>mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle>
              <span>
                <v-chip
                  v-if="item.messageTypeFk == 4"
                  class="message-badge"
                  color="blue"
                >
                  <router-link
                    style="color: white"
                    :to="{
                      name: 'Letter',
                      params: {
                        create: false,
                        pagetype: 'Inbox',
                        messagetype: item.messageTypeFk,
                        id: item.messageId,
                      },
                    }"
                    ><span>{{ item.messageTypeString }}</span>
                  </router-link>
                </v-chip>
                <v-chip
                  v-else-if="item.messageTypeFk == 5"
                  class="message-badge"
                  color="pink"
                >
                  <router-link
                    style="color: white"
                    :to="{
                      name: 'Letter',
                      params: {
                        create: false,
                        pagetype: 'Inbox',
                        messagetype: item.messageTypeFk,
                        id: item.messageId,
                      },
                    }"
                    ><span>{{ item.messageTypeString }}</span>
                  </router-link>
                </v-chip>
                <v-chip v-else class="message-badge" color="teal">
                  <router-link
                    style="color: white"
                    :to="{
                      name: 'Letter',
                      params: {
                        create: false,
                        pagetype: 'Inbox',
                        messagetype: item.messageTypeFk,
                        id: item.messageId,
                      },
                    }"
                    ><span>{{ item.messageTypeString }}</span>
                  </router-link>
                </v-chip>
              </span>
              <span>
                {{ item.messageFrom }}
              </span>
            </v-list-item-subtitle>
            <span>{{ item.subject }}</span>
            <span v-html="item.messageBody" class="message-body"> </span>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-col>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "HomeMessage",
  data() {
    return {
      headers1: [
        {
          text: "نوع نامه",
          value: "messageTypeString",
          sortable: false,
          align: "center",
        },
        {
          text: "وضعیت نامه",
          value: "isReaded",
          sortable: false,
          align: "center",
        },
        {
          text: "موضوع نامه",
          value: "subject",
          sortable: false,
          align: "start",
        },
        {
          text: "فرستنده نامه",
          value: "messageFrom",
          sortable: false,
          align: "center",
        },
        {
          text: "شماره نامه",
          value: "displayMessageNumber",
          align: "center",
          sortable: false,
        },
        { text: "تاریخ", value: "createdDate", align: "center" },
        { text: "", value: "title7", align: "center", sortable: false },
      ],
      desserts1: [],
    };
  },
  computed: {
    ...mapGetters(["getUrl", "getBaseUrl"]),
  },
  mounted() {
    this.getMessageList();
  },
  methods: {
    getMessageList() {
      axios
        .get(`${this.getUrl}/message/list/`, {
          params: {
            Count: 10,
            Page: 1,
            Language: 0,
            MessageType: 0,
            TypeNumber: "string",
            PageType: "FavoritePerson",
          },
        })
        .then((data) => {
          this.desserts1 = data.data.records;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.message-body {
  white-space: nowrap;
  max-width: 100%;
  max-height: 60px;
}
.message-body p {
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-badge {
  margin-left: 8px;
}

.message-badge >>> a {
  text-decoration: auto;
}
.v-list-item__subtitle {
  margin-bottom: 5px;
}
</style>

 
<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <!-- //////////////////////////////////////////////////////////////////////////////////////// -->
          <v-card class="rounded-lg">
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">آیا اطمینان دارید ؟</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue-darken-1"
                    variant="text"
                    @click="closeDelete"
                    >لغو</v-btn
                  >
                  <v-btn color="error" variant="text" @click="deleteItemConfirm"
                    >بله</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="addForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="addForm = false">
                  ایجاد
                </v-card-title-close>
                <v-form ref="form">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" lg="8">
                        <v-text-field
                          :rules="nameRules"
                          label="عنوان"
                          hide-details
                          dense
                          outlined
                          v-model="addData.title"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          label="پیشوند"
                          hide-details
                          dense
                          outlined
                          v-model="addData.prefix"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          type="number"
                          hide-details
                          label="عدد شروع"
                          dense
                          outlined
                          v-model="addData.startNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-select
                          label="نوع نامه"
                          hide-details
                          :items="typeMessageArray"
                          item-text="title"
                          item-value="value"
                          dense
                          outlined
                          v-model="addData.messageTypeFk"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-checkbox
                          label="فعال"
                          hide-details
                          dense
                          v-model="addData.active"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-checkbox
                          label="پیش فرض"
                          hide-details
                          dense
                          v-model="addData.default"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="addItem()" class="successs">افزودن</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="editForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="editForm = false">
                  ویرایش
                </v-card-title-close>
                <v-form ref="formEdit">
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" lg="8">
                        <v-text-field
                          :rules="nameRules"
                          label="عنوان"
                          hide-details
                          dense
                          outlined
                          v-model="editData.data.andikatorTitle"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          label="پیشوند"
                          hide-details
                          dense
                          outlined
                          v-model="editData.data.andikatorPreFix"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          type="number"
                          hide-details
                          label="عدد شروع"
                          dense
                          outlined
                          v-model="editData.data.startNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-select
                          label="نوع نامه"
                          hide-details
                          dense
                          :items="typeMessageArray"
                          item-text="title"
                          item-value="value"
                          outlined
                          v-model="editData.data.messageTypeFk"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-checkbox
                          label="فعال"
                          hide-details
                          dense
                          v-model="editData.data.isActive"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-checkbox
                          label="پیش فرض"
                          hide-details
                          dense
                          v-model="editData.data.isDefault"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="editItem()" class="success">ویرایش</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-card-actions>
              <v-row>
                <v-col cols="6">
                  <v-card-title>{{ title }}</v-card-title>
                </v-col>
                <v-col cols="6" class="text-end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-3 mt-3"
                        color="warning"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        v-on:click="sendShowHelp"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>راهنما</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12">
                  <v-btn class="mr-2 success" @click="addForm = true">
                    <v-icon small>mdi-plus</v-icon>
                    افزودن
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
            <v-card-title>
              <v-text-field
                v-model="search"
                outlined
                dense
                append-icon="mdi-magnify"
                label="جستجو"
                @input="searchBoxChanged"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <div>
                <v-data-table
                  :headers="headers"
                  :items="getAndikatorsList.records"
                  hide-default-footer
                  :items-per-page="20"
                  :server-items-length="getAndikatorsList.record_count"
                  :page="1"
                  :fixed-header="true"
                  :loading="getLoading"
                >
                  <template v-slot:[`item.andikatorFk`]="{ item }">
                    <span dense>{{ getAndikatorFk(item.andikatorFk) }}</span>
                  </template>
                  <template v-slot:[`item.isDefault`]="{ item }">
                    <v-simple-checkbox
                      disabled
                      dense
                      v-model="item.isDefault"
                    ></v-simple-checkbox>
                  </template>
                  <template v-slot:[`item.isActive`]="{ item }">
                    <v-simple-checkbox
                      disabled
                      dense
                      v-model="item.isActive"
                    ></v-simple-checkbox>
                  </template>
                  <template v-slot:[`item.messageTypeFk`]="{ item }">
                    <span dense>{{
                      getMessageTypeFk(item.messageTypeFk)
                    }}</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dense
                          class="ma-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="openFormEdit(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>ویرایش</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          color="error"
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>حذف</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div class="text-center pt-2"></div>
                <v-row>
                  <v-col
                    cols="12"
                    v-if="Math.ceil(getAndikatorsList?.record_count / 20) > 1"
                  >
                    <v-pagination
                      v-model="page"
                      :length="
                        getAndikatorsList?.record_count > 0
                          ? Math.ceil(getAndikatorsList.record_count / 20)
                          : 0
                      "
                      @input="handlePageChange"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import MenuLeft from "../../components/MenuLeft.vue";
import VCardTitleClose from "../../components/VCardTitleClose";

import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
import axios from "axios";

export default {
  name: "Andikator",
  mixins: [myMixin],
  components: {
    VCardTitleClose,
    MenuLeft,
  },
  data() {
    return {
      search: "",
      page: 1,
      title: "شماره گذاری اسناد",
      dialogDelete: false,
      addForm: false,
      editForm: false,
      addData: {
        url: "andikator/create/",
        title: null,
        prefix: null,
        startNumber: null,
        messageTypeFk: null,
        typeMessage: null,
        active: false,
        default: false,
        test: this.$refs,
      },
      editData: {
        url: "andikator/update/",
        data: {
          andikatorId: null,
          andikatorPreFix: "",
          andikatorTitle: "",
          domainFk: null,
          isActive: true,
          isDefault: true,
          isDemo: false,
          lastMessageNumber: null,
          message: [],
          messageTypeFk: null,
          startNumber: null,
          systemDefault: false,
        },
      },
      removeData: null,
      headers: [
        {
          text: "عنوان ",
          align: "right",
          value: "andikatorTitle",
        },
        {
          text: "پیشوند",
          align: "center",
          value: "andikatorPreFix",
        },
        {
          text: "شروع",
          align: "center",
          value: "startNumber",
        },
        {
          text: "آخرین",
          align: "center",
          value: "lastMessageNumber",
        },
        {
          text: "نوع نامه",
          align: "center",
          value: "messageTypeFk",
        },
        {
          text: "فعال",
          align: "center",
          value: "isActive",
        },
        {
          text: "پیش فرض",
          align: "center",
          value: "isDefault",
        },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
      typeMessageArray: [
        { title: "داخلی", value: 4 },
        { title: "وارده", value: 5 },
        { title: "صادره", value: 6 },
      ],
    };
  },
  mounted() {
    this.getPage(1);
  },
  computed: {
    ...mapGetters(["getAndikatorsList", "getUrl", "getLoading"]),
  },
  methods: {
    getPage(page) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "andikator/list/",
        page: page,
        phrase: this.search,
      });
    },
    handlePageChange(page) {
      this.getPage(page);
    },
    searchBoxChanged() {
      this.getPage(1);
    },

    addItem() {
      this.$store.dispatch("sendNewitem", { data: this.addData });
      this.addForm = false;
    },

    openFormEdit(item) {
      this.editForm = true;
      this.editData.data = JSON.parse(JSON.stringify(item));
    },
    editItem() {
      if (this.$refs.formEdit.validate()) {
        let data = {
          Id: this.editData.data.andikatorId,
          AndikatorPreFix: this.editData.data.andikatorPreFix,
          AndikatorTitle: this.editData.data.andikatorTitle,
          IsActive: this.editData.data.isActive,
          IsDefault: this.editData.data.isDefault,
          StartNumber: this.editData.data.startNumber,
          MessageTypeFk: this.editData.data.messageTypeFk,
          Language: 0,
        };

        axios
          .put(`${this.getUrl}/andikator/update/`, data)
          .then((data) => {
            if (data.data.status == 1) {
              this.getPage(1);
              this.$store.commit("setMessage", {
                message: "درخواست با موفقیت انجام شد",
                color: "success",
              });
              this.editForm = false;
            } else if (data.data.status == 0) {
              this.$store.commit("setMessage", {
                message: data.data.message,
                color: "red",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$store.commit("changeLoading", false);
          });
      }
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.removeData = item;
      this.removeData.url = "andikator/delete/";
    },
    deleteItemConfirm() {
      this.$store.dispatch("sendRemoveItem", {
        data: this.removeData,
      });
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    sendShowHelp() {
      this.$emit("ShowHelp", true);
    },
    getAndikatorFk(id) {
      let result = this.getAndikatorsList.records.find((item) => {
        return item.andikatorId == id;
      });
      return result ? result.andikatorTitle : "";
    },
    getMessageTypeFk(id) {
      let result = this.typeMessageArray.find((item) => {
        return item.value == id;
      });
      return result ? result.title : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>

import { render, staticRenderFns } from "./LettersIssued.vue?vue&type=template&id=5a66142b&scoped=true"
import script from "./LettersIssued.vue?vue&type=script&lang=js"
export * from "./LettersIssued.vue?vue&type=script&lang=js"
import style0 from "./LettersIssued.vue?vue&type=style&index=0&id=5a66142b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a66142b",
  null
  
)

export default component.exports
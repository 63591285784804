
<template>
  <v-col cols="12" sm="12" lg="7">
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title-close @close="dialog = false">
          افزودن افراد پر مکاتبه
        </v-card-title-close>
        <v-card-text>
          <v-form ref="form">
            <v-col cols="12">
              <v-autocomplete
                v-model="person"
                :items="this.getPersonFilterd"
                :item-text="(item) => `${item.firstName} ${item.lastName}`"
                item-value="id"
                outlined
                dense
                hide-details
                chips
                small-chips
                label="اشخاص"
                multiple
              ></v-autocomplete>
            </v-col>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn v-on:click="addPerons" @click="dialog = false" color="success">
            ذخیره
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="rounded-lg scrollable-card" flat>
      <v-card-title>
        <v-row>
          <v-col cols="12" lg="6" style="font-size: 1rem"
            >افراد پر مکاتبه</v-col
          >
          <v-col cols="12" lg="6" class="text-left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  v-on:click="dialog = !dialog"
                >
                  mdi-dots-vertical
                </v-icon>
              </template>
              <span>ویرایش</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
      <!-- <v-divider></v-divider>
      <v-list dense>
        <template v-for="(item, index) in this.getFavoriteFilterd">
          <v-subheader
            v-if="item.header"
            :key="item.header"
            v-text="item.header"
          ></v-subheader>
          <v-divider
            v-else-if="item.divider"
            :key="index"
            :inset="item.inset"
          ></v-divider>
          <v-list-item v-else :key="item.name">
            <v-list-item-avatar color="grey lighten-3">
              <v-icon color="grey lighten-1">mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list> -->
    </v-card>
    <v-divider></v-divider>

    <HomeMessage :key="reload" />
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import HomeMessage from "./HomeMessage.vue";
import VCardTitleClose from "../../components/VCardTitleClose";

export default {
  name: "FavoritePerson",
  components: {
    VCardTitleClose,
    HomeMessage,
  },
  data() {
    return {
      dialog: false,
      person: null,
      reload: 1,
    };
  },
  computed: {
    ...mapGetters(["getPersonList", "getFavoritePersons", "getUrl"]),
    getPersonFilterd() {
      return this.getPersonList.records?.filter(
        (x) => x.id != this.$store.state.CurrentUser.userId
      );
    },
    getFavoriteFilterd() {
      return this.getFavoritePersons.records?.filter(
        (x) => x.id != this.$store.state.CurrentUser.userId
      );
    },
  },
  mounted() {
    this.getPerson();
    this.getfavoritepersons();
  },
  methods: {
    addPerons() {
      axios
        .post(`${this.getUrl}/person/savefavoritepersons/`, {
          Persons: this.person,
          Language: 0,
        })
        .then((response) => {
          this.reload++;
          console.log(this.reload);
          this.getfavoritepersons();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getfavoritepersons() {
      this.$store.dispatch("sendListFavoritePersons", {
        url: "person/getfavoritepersons/",
      });
    },
    getPerson() {
      this.$store.dispatch("sendList", { url: "person/list/", page: 1 });
    },
  },
};
</script>

<style>
</style>
<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            :items="getDocumentList"
            v-on:onAttachmentUpload="uploadAttachment"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="true"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="formAdd">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        :rules="requiredRule"
                        label="نوع سند"
                        hide-details
                        outlined
                        :items="documentType"
                        item-text="title"
                        item-value="value"
                        dense
                        v-model="addData.documentType"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="مرکز حساب"
                        hide-details
                        outlined
                        :items="accountType"
                        item-text="title"
                        item-value="value"
                        dense
                        v-model="addData.accountType"
                        :rules="accountTypeRules"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="سر فصل"
                        hide-details
                        outlined
                        :items="getAccountsList.records"
                        item-text="fullTitle"
                        item-value="accountId"
                        dense
                        v-model="addData.accountFk"
                        :rules="requiredRule"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-if="addData.accountType == 2"
                        label="حساب بانکی"
                        hide-details
                        outlined
                        :items="getBankAccountList.records"
                        item-text="number"
                        item-value="bankFk"
                        dense
                        v-model="addData.bankAccountFk"
                      >
                      </v-select>
                      <v-select
                        v-else
                        label="صندوق"
                        hide-details
                        outlined
                        :items="getFundsList.records"
                        item-text="title"
                        item-value="cashDeskId"
                        dense
                        v-model="addData.cashDeskFk"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <!-- <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="addData.Date"
                          label="تاریخ سند"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          outlined
                          dense
                          hide-details
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="addData.Date"
                        @change="updateAddDataStartDate(addData.Date)"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu> -->
                      <v-row>
                        <v-col cols="2"
                          ><v-icon
                            type="button"
                            class="mt-3"
                            @click="show1 = true"
                          >
                            mdi-calendar
                          </v-icon></v-col
                        >
                        <v-col cols="10">
                          <v-text-field
                            v-model="addData.date"
                            id="addData.date"
                            type="text"
                            outlined
                            dense
                            label="تاریخ سند"
                            @click="show1 = true"
                            :rules="requiredRule"
                            readonly
                          ></v-text-field>
                          <date-picker
                            v-model="addData.date"
                            :show="show1"
                            :auto-submit="true"
                            :editable="true"
                            custom-input="#addData.date"
                            @close="show1 = false"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="مبلغ"
                        outlined
                        dense
                        hide-details
                        v-model="addData.price"
                        :rules="requiredRule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        outlined
                        hide-details
                        label="توضیح"
                        rows="4"
                        row-height="30"
                        v-model="addData.description"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="پروژه"
                        hide-details
                        outlined
                        :items="getProjectList.records"
                        item-text="projectTitle"
                        item-value="projectId"
                        dense
                        v-model="addData.projectFk"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="قرارداد"
                        hide-details
                        outlined
                        :items="getContractList.records"
                        item-text="contractTitle"
                        item-value="contractId"
                        dense
                        v-model="addData.contractFk"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="شخص"
                        hide-details
                        outlined
                        :items="getPersonList.records"
                        :item-text="
                          (item) => `${item.firstName} ${item.lastName}`
                        "
                        item-value="id"
                        dense
                        v-model="addData.userId"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="مشتری"
                        hide-details
                        outlined
                        :items="getCustomerList.records"
                        item-text="name"
                        item-value="customerId"
                        dense
                        v-model="addData.customerFk"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-select
                        :rules="nameRules"
                        label="نوع سند"
                        hide-details
                        outlined
                        :items="documentType"
                        item-text="title"
                        item-value="value"
                        dense
                        v-model="editData.data.documentType"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        :rules="accountTypeRules"
                        label="مرکز حساب"
                        hide-details
                        outlined
                        :items="accountType"
                        item-text="title"
                        item-value="value"
                        dense
                        v-model="editData.data.accountType"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="سر فصل"
                        hide-details
                        outlined
                        :items="getAccountsList.records"
                        item-text="fullTitle"
                        item-value="accountId"
                        dense
                        v-model="editData.data.accountFk"
                        :rules="requiredRule"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-if="editData.data.accountType == 1"
                        label="صندوق"
                        hide-details
                        outlined
                        :items="getFundsList.records"
                        item-text="title"
                        item-value="cashDeskId"
                        dense
                        v-model="editData.data.cashDeskFk"
                      >
                      </v-select>
                      <v-select
                        v-else
                        label="حساب بانکی"
                        hide-details
                        outlined
                        :items="getBankAccountList.records"
                        item-text="number"
                        item-value="bankFk"
                        dense
                        v-model="editData.data.bankAccountFk"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <!-- <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editData.data.Date"
                          label="تاریخ سند"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          outlined
                          dense
                          hide-details
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editData.data.Date"
                        @change="updateAddDataStartDate(editData.data.Date)"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu> -->
                      <v-row>
                        <v-col cols="2"
                          ><v-icon
                            type="button"
                            class="mt-3"
                            @click="show2 = true"
                          >
                            mdi-calendar
                          </v-icon></v-col
                        >
                        <v-col cols="10">
                          <v-text-field
                            v-model="editData.data.date"
                            id="editData.data.date"
                            type="text"
                            outlined
                            dense
                            label="تاریخ سند"
                            @click="show2 = true"
                            :rules="requiredRule"
                            readonly
                          ></v-text-field>
                          <date-picker
                            v-model="editData.data.date"
                            :show="show2"
                            :auto-submit="true"
                            :editable="true"
                            custom-input="#editData.data.date"
                            @close="show2 = false"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="مبلغ"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.price"
                        :rules="requiredRule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        outlined
                        hide-details
                        label="توضیح"
                        rows="4"
                        row-height="30"
                        v-model="editData.data.description"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="پروژه"
                        hide-details
                        outlined
                        :items="getProjectList.records"
                        item-text="projectTitle"
                        item-value="projectId"
                        dense
                        v-model="editData.data.projectFk"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="قرارداد"
                        hide-details
                        outlined
                        :items="getContractList.records"
                        item-text="contractTitle"
                        item-value="contractId"
                        dense
                        v-model="editData.data.contractFk"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="شخص"
                        hide-details
                        outlined
                        :items="getPersonList.records"
                        :item-text="
                          (item) => `${item.firstName} ${item.lastName}`
                        "
                        item-value="id"
                        dense
                        v-model="editData.data.userId"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        label="مشتری"
                        hide-details
                        outlined
                        :items="getCustomerList.records"
                        item-text="name"
                        item-value="customerId"
                        dense
                        v-model="editData.data.customerFk"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:attachmentForm>
              <v-card-text>
                <DataTableChild
                  :headers="headersAttachment"
                  v-on:sendDataRemoveAttachment="getDataRemoveAttachment"
                  v-on:sendDataDownloadAttachment="getDataAttachment"
                  v-on:sendData="getData"
                  :sendList="attachListTest"
                  :sendEditData="editData"
                  :sendremoveData="removeData"
                  :plus="false"
                  :download="true"
                  :edit="false"
                  :downloadUrl="getUrl + '/document/download'"
                >
                </DataTableChild>
              </v-card-text>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import AccountingSystem from "../../components/AccountingSystem.vue";
import DataTableChild from "../../components/DataTableChild.vue";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { myMixin } from "../../../mixins.js";
import { mapGetters } from "vuex";
import moment from "jalali-moment";
export default {
  name: "Banks",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
    DataTableChild,
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      accountTypeRules: [(v) => (v != undefined && v >= 0) || "الزامی است"],
      searchTest: "",
      attachListTest: [],
      show1: false,
      show2: false,
      addData: {
        url: "document/create/",
        formName: "formAdd",
        documentType: null,
        accountType: null,
        bankAccountFk: null,
        cashDeskFk: null,
        date: null,
        price: null,
        description: null,
        projectFk: null,
        contractFk: null,
        customerFk: null,
        userId: null,
        accountFk: null,
      },
      editData: {
        url: "document/update/",
        formName: "formEdit",
        data: {
          documentId: 0,
          documentType: null,
          accountType: null,
          bankAccountFk: null,
          cashDeskFk: null,
          date: null,
          price: null,
          description: null,
          projectFk: null,
          contractFk: null,
          customerFk: null,
          companyFk: null,
          accountFk: null,
          userId: null,
        },
      },
      removeData: { url: "document/delete/" },
      menu1: false,
      menu2: false,
      documentType: [
        {
          title: "پرداخت",
          value: 1,
        },
        {
          title: "دریافت",
          value: 2,
        },
      ],
      accountType: [
        {
          title: "صندوق",
          value: 1,
        },
        {
          title: "بانک",
          value: 2,
        },
      ],
      paymentMethod: [
        { title: "تاریخ ثابت", value: 0 },
        { title: "روزانه", value: 1 },
        { title: "سالیانه", value: 2 },
        { title: "ماهانه", value: 3 },
        { title: "هفتگی", value: 4 },
      ],
      contracType: [
        {
          title: "داخلی",
          value: 1,
        },
        {
          title: "خارجی",
          value: 2,
        },
      ],
      headers: [
        {
          text: "شرح",
          align: "right",
          value: "description",
        },
        {
          text: "مبلغ",
          align: "center",
          value: "price",
        },
        {
          text: "تاریخ",
          align: "center",
          value: "date",
        },
        // {
        //   text: "پیوست",
        //   align: "center",
        //   value: "documentAttachments",
        // },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
      headersAttachment: [
        {
          text: "عنوان فایل",
          align: "center",
          value: "attachmentFileName",
        },
        {
          text: "ایجاد کننده",
          align: "center",
          value: "creatorUserId",
        },
        {
          text: "تاریخ",
          align: "center",
          value: "createdDate",
        },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
      ContractpaymentListTest: {
        records: [
          {
            VchNumber: "test",
            Price: 12000,
            DeliveryDate: "asdasd",
            Duedate: "1402/01/01",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      "getDocumentList",
      "getContractList",
      "getContractpaymentList",
      "getCustomerList",
      "getPersonList",
      "getProjectList",
      "getContractgroupList",
      "getBankAccountList",
      "getFundsList",
      "getAccountsList",
      "getUrl",
    ]),
  },
  created() {
    this.getdocument();
    this.getContract();
    this.getContractCategory();
    this.getPerson();
    this.getProject();
    this.getCustomer();
    this.getBankAccount();
    this.getFunds();
    this.getAccounts();
  },
  methods: {
    getDataAttachment(item) {
      axios
        .get(`${this.getUrl}/customer/download/`, {
          params: {
            id: item.customerAttachmentId,
            Language: 0,
          },
        })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataRemoveAttachment(item) {
      console.log(item);
      axios
        .delete(`${this.getUrl}/customer/deleteuploadfile/`, {
          Id: item.documentAttachmentId,
          Language: 0,
        })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    uploadAttachment(item) {
      debugger;
      axios
        .post(
          `${this.getUrl}/document/uploadfile/`,
          {
            DocumentFk: item.item.documentId,
            Attachment: item.attachment,
            Language: 0,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.getData(item.itemId);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "document/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      // console.log(item);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "document/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getdocument() {
      this.$store.dispatch("sendList", {
        url: "document/list/",
        page: 1,
        Phrase: "",
      });
    },
    getPerson() {
      this.$store.dispatch("sendList", {
        url: "person/list/",
        page: 1,
        Phrase: "",
      });
    },
    getCustomer() {
      this.$store.dispatch("sendList", {
        url: "customer/list/",
        page: 1,
        Phrase: "",
      });
    },
    getContractCategory() {
      this.$store.dispatch("sendList", {
        url: "contractgroup/list/",
        page: 1,
        Phrase: "",
      });
    },
    getContract() {
      this.$store.dispatch("sendList", {
        url: "contract/list/",
        page: 1,
        Phrase: "",
      });
    },
    getBankAccount() {
      this.$store.dispatch("sendList", {
        url: "bankaccount/list/",
        page: 1,
        Phrase: "",
      });
    },
    getFunds() {
      this.$store.dispatch("sendList", {
        url: "cashdesk/list/",
        page: 1,
        phrase: "",
      });
    },
    getAccounts() {
      this.$store.dispatch("sendList", {
        url: "account/list/",
        page: 1,
        phrase: "",
      });
    },
    getData(item) {
      axios
        .get(`${this.getUrl}/document/get/`, {
          params: {
            Id: item.documentId,
            Language: 0,
          },
        })
        .then((response) => {
          for (const key in response.data.record) {
            if (this.editData.data.hasOwnProperty(key)) {
              this.editData.data[key] = response.data.record[key];
            }
          }
          if (response.data.record.cashDeskFk)
            this.editData.data.accountType = 1;
          else this.editData.data.accountType = 2;
          this.attachListTest = response.data.attachments;
          this.editData.data.date = this.toPersianDate(this.editData.data.date);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProject() {
      this.$store.dispatch("sendList", {
        url: "project/list/",
        page: 1,
        Phrase: "",
      });
    },
    updateAddDataStartDate(date) {
      // console.log(date, "ffffff");
      // this.formattedDate = moment(this.editData.data.StartDate, 'YYYY/MM/DD').format('jYYYY/jM/jD');
      this.addData.date = moment(date).locale("fa").format("YYYY-M-D");
    },
    updateAddDataEndDate(date) {
      // console.log(date, "ffffff");
      this.addData.endDate = moment(date).locale("fa").format("YYYY-M-D");
    },
    updateEditDataStartDate(date) {
      // this.editData.data.StartDate = moment(date).locale('fa').format('YYYY-M-D');
    },

    updateEditDataEndDate(date) {
      // this.editData.data.EndDate = moment(date).locale('fa').format('YYYY-M-D');
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations.js";
import actions from "./actions.js";
import state from "./state.js";
import getters from "./getters.js";
import project from "../latelier/imports/store/modules/project";

Vue.use(Vuex);
// crm.businesssoftware.ir
// https://automation.dayavo.com/api
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    project,
  },
});

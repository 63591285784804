<template>
  <section class="help" style="margin-top: 8rem">
    <v-container>
      <v-row justify="center">
        {{ change() }}
        <!-- <v-dialog
      v-model="sendDataHelp.modal"
      max-width="600px"
      
    > -->

        <v-card
          v-if="sendDataHelp.modal"
          class="rounded-xl text-center"
          :style="cardStyle"
        >
          <v-container>
            <v-card-actions>
              <v-row>
                <v-col cols="12" class="text-end">
                  <v-icon @click="resizeCard">mdi-resize</v-icon>
                  <v-icon v-on:click="sendShowHelp">mdi-close</v-icon>
                </v-col>
              </v-row>
            </v-card-actions>
            <v-row>
              <v-col cols="12" v-if="showTitle">
                <!-- <v-card-title style="text-align:center!important"> -->
                <span
                  class="text-h6"
                  style="font-family: Vazirmatn !important"
                  >{{ sendDataHelp.title }}</span
                >
                <!-- </v-card-title> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="showText">
                <v-card
                  style="margin: auto"
                  max-width="700"
                  class="text-center"
                  flat
                >
                  <v-card-text>
                    {{ sendDataHelp.des }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col col="12">
                <v-card flat class="rounded-lg">
                  <video controls style="width: 90%" ref="video" name="media">
                    <source :src="sendDataHelp.video" type="video/mp4" />
                  </video>
                </v-card>
              </v-col>
              <v-col cols="12" v-if="showVoice">
                <audio preload="none" style="width: 90%" controls="controls">
                  <source :src="sendDataHelp.audio" type="audio/mpeg" />
                </audio>
              </v-col>
            </v-row>
          </v-container>
          <v-card-text> </v-card-text>
        </v-card>
        <!-- </v-dialog> -->
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "Help",
  props: ["sendDataHelp"],
  data() {
    return {
      showTitle: true,
      showText: true,
      showVoice: true,
      cardStyle: {
        width: "900px",
        top: "2%",
        right: "7%",
        transition: "width 0.3s",
      },
    };
  },
  methods: {
    sendShowHelp() {
      this.$emit("ShowHelp", false);
    },
    resizeCard() {
      // console.log('object');
      this.cardStyle.width =
        this.cardStyle.width === "900px" ? "320px" : "900px";
      this.cardStyle.top = this.cardStyle.top === "2%" ? "-40%" : "2%";
      this.cardStyle.right = this.cardStyle.right === "7%" ? "328%" : "7%";
      this.showText = !this.showText;
      this.showVoice = !this.showVoice;
      this.showTitle = !this.showTitle;
      // this.$refs.video.style.width = this.showText?this.$refs.video.style.width = '70':this.$refs.video.style.width = '100';
      // console.log(this.$refs.video.style.width);
      if (!this.showText || this.showText) {
        // console.log(this.$refs.video.currentTime);
        let time = this.$refs.video.currentTime;
        // console.log(this.$vuetify.breakpoint.mobile);
        setTimeout(() => {
          this.$refs.video.currentTime = time;
          this.$refs.video.play();
        }, 500);
      }
    },
    handleResize() {},
    change() {
      if (this.$vuetify.breakpoint.mobile) {
        this.cardStyle.width =
          this.cardStyle.width === "900px" ? "90%" : "900px";
        this.cardStyle.top = 0;
        this.cardStyle.right = "0.7rem";
      }
    },
  },
  watch: {
    "this.$vuetify.breakpoint.mobile"() {
      // console.log('object');
    },
  },
};
</script>

<style>
.help {
  position: fixed;
  z-index: 1;
}
</style>
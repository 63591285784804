<template>
  <v-card-title class="justify-space-between">
    <slot></slot>
    <v-btn class="mx-2" icon @click="$emit('close')">
      <v-icon> mdi-close </v-icon>
    </v-btn>
  </v-card-title>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getListBySearch="getSearch"
            v-on:getList="getPage"
            :items="getUsergroupList"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="form">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان گروه کاربری"
                        v-model="addData.userGroupTitle"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="addData.systemFk"
                        :items="getSystemList.records"
                        item-text="title"
                        item-value="Id"
                        label="عنوان سیستم"
                        hide-details
                        outlined
                        dense
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="12">
                      <v-autocomplete
                        v-model="addData.userGroupMembers"
                        outlined
                        :items="getPageactionList.records"
                        item-text="title"
                        item-value="Id"
                        dense
                        hide-details
                        chips
                        small-chips
                        label="دسترسی صفحات"
                        multiple
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-checkbox
                        v-model="addData.userType2"
                        label="مدیران"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-checkbox
                        v-model="addData.userType3"
                        label="کارمندان"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-checkbox
                        v-model="addData.isActive"
                        label="فعال"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        :rules="nameRules"
                        v-model="editData.data.userGroupTitle"
                        label="عنوان گروه کاربری"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-select
                        v-model="editData.data.systemFk"
                        :items="getSystemList.records"
                        item-text="title"
                        item-value="id"
                        label="عنوان سیستم"
                        hide-details
                        outlined
                        dense
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" lg="12">
                      <v-autocomplete
                        v-model="editData.data.userGroupMembers"
                        outlined
                        :items="getPageactionList.records"
                        item-text="title"
                        item-value="id"
                        dense
                        hide-details
                        chips
                        small-chips
                        label="دسترسی صفحات"
                        multiple
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-checkbox
                        v-model="editData.data.userType2"
                        label="مدیران"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-checkbox
                        v-model="editData.data.userType3"
                        label="کارمندان"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-checkbox
                        v-model="editData.data.isActive"
                        label="فعال"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
export default {
  name: "Banks",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      searchTest: "",
      addData: {
        url: "usergroup/create/",
        userGroupTitle: null,
        systemFk: null,
        isActive: true,
        userType2: true,
        userType3: true,
        userGroupMembers: [],
        pageActions: [],
        test: this.$refs,
      },
      editData: {
        url: "usergroup/update/",
        data: {
          userGroupTitle: null,
          systemFk: null,
          isActive: true,
          userType2: true,
          userType3: true,
          userGroupMembers: [],
          pageActions: [],
        },
      },
      removeData: { url: "usergroup/delete/" },
      headers: [
        {
          text: "عنوان گروه های کاربری",
          align: "center",
          value: "userGroupTitle",
        },
        {
          text: "سیستم",
          align: "center",
          value: "systemFk",
        },
        {
          text: "فعال",
          align: "center",
          value: "isActive",
        },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getUsergroupList",
      "getPageactionList",
      "getSystemList",
      "getUrl",
    ]),
  },
  created() {
    this.getBanks();
    this.getPageaction();
    this.getSystem();
  },
  methods: {
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "usergroup/list/",
        page: 1,
        phrase: search,
      });
    },
    getPageaction() {
      this.$store.dispatch("sendList", {
        url: "pageaction/list/",
        page: 1,
        phrase: this.searchTest,
      });
    },
    getSystem() {
      this.$store.dispatch("sendList", {
        url: "system/list/",
        page: 1,
        Phrase: "",
      });
    },
    getPage(item) {
      // console.log(item);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (this.searchTest == "") {
        this.$store.dispatch("sendList", {
          url: "usergroup/list/",
          page: item,
          Phrase: "",
        });
      } else {
        return;
      }
    },
    getBanks() {
      this.$store.dispatch("sendList", {
        url: "usergroup/list/",
        page: 1,
        Phrase: "",
      });
    },
    getData(item) {
      // console.log(item, "----------------");
      axios
        .get(`${this.getUrl}/usergroup/get/`, {
          params: {
            Id: item.userGroupId,
            Language: 0,
          },
        })
        .then((response) => {
          // console.log(response);
          this.editData.data = response.data.record;
          this.editData.data.test = this.$refs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>

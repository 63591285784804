<template>
  <v-card>
    <v-card-title>تنظیمات حساب کاربری</v-card-title>

    <v-col cols="12">
      <v-text-field
        outlined
        hide-details
        dense
        label="کلمه عبور فعلی "
        placeholder="xxxxxxxxxx"
        v-model="item.currentPassword"
        type="password"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field
        outlined
        hide-details
        dense
        label="کلمه عبور جدید "
        placeholder="xxxxxxxxxx"
        v-model="item.newPassword"
        type="password"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-file-input
        v-model="item.userProfilePic"
        color="deep-purple-accent-4"
        counter
        label="تصویر پروفایل "
        placeholder="انتخاب"
        prepend-icon="mdi-paperclip"
        variant="outlined"
        :show-size="1000"
        v-show="true"
        accept="image/*"
      ></v-file-input>
    </v-col>
    <v-col cols="12">
      <v-file-input
        v-model="item.userSignPic"
        color="deep-purple-accent-4"
        counter
        label="تصویر امضاء "
        placeholder="انتخاب"
        prepend-icon="mdi-paperclip"
        variant="outlined"
        :show-size="1000"
        v-show="true"
        accept="image/*"
      ></v-file-input>
    </v-col>
    <v-divider></v-divider>
    <v-card-actions class="justify-center">
      <v-btn v-on:click="editItem()" color="success">ویرایش</v-btn>
      <v-btn v-on:click="$emit('close')">لغو</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "UserSetting",
  components: {},
  data() {
    return {
      dialog: true,
      item: {
        currentPassword: "",
        newPassword: "",
        userProfilePic: null,
        userSignPic: null,
      },
    };
  },
  computed: {
    ...mapGetters(["getUrl", "getBaseUrl"]),
  },
  created() {},
  methods: {
    editItem() {
      axios
        .put(
          `${this.getUrl}/user/changeusersetting/`,
          {
            Language: 0,
            CurrentPassword: this.item.currentPassword,
            NewPassword: this.item.newPassword,
            UserProfilePic: this.item.userProfilePic,
            UserSignPic: this.item.userSignPic,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.$store.commit("setMessage", {
              message: data.data.message,
              color: "red",
              timeOut: 2000,
            });
            return;
          }
          this.$store.commit("setMessage", {
            message: "درخواست با موفقیت انجام شد",
            color: "success",
            timeOut: 2000,
          });
          localStorage.setItem("pic", data.data.profilePicture);
          this.$store.state.CurrentUser.pic =
            this.getBaseUrl + "media/userpic/" + data.data.profilePicture;
          this.$emit("close");
        })
        .catch((error) => {
          this.$store.commit("setMessage", {
            message: error.message,
            color: "red",
            timeOut: 2000,
          });
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
